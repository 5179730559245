<template>
  <LoaderSection v-show="!userList && !userError" />
  <NavigationSection />
  <main ref="root">
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content p-3 pt-4">
              <div class="clearfix my-auto">
                <h1 class="fw-bold h3 text-dark float-start"><font-awesome-icon :icon="['far', 'fa-address-book']" />Users</h1>
                <form class="float-end" method="get" action="" id="integratorForm">
                  <div class="form-floating">
                    <select class="form-select bg-light" id="integratorId" name="integratorId" required>
                      <option value="1WTManOJBP7EKsy2TElo7I" selected="selected">Efty.com</option>
                    </select>
                    <label for="environment">Integrator</label>
                  </div>
                </form>
              </div>
              <div class="bg-white border p-3 mt-4">
                <div class="table-responsive">
                  <table class="table table-responsive table-borderless bg-white largecells">
                    <thead class="">
                    <tr>
                      <th scope="col" width="20%" class="text-muted fw-bold lead bg-white">Name</th>
                      <th scope="col" width="15%" class="text-muted fw-bold lead bg-white">Account type</th>
                      <th scope="col" width="15%" class="text-muted fw-bold lead bg-white">Mangopay user</th>
                      <th scope="col" width="15%" class="text-muted fw-bold lead bg-white">Mangopay Verified</th>
                    </tr>
                    <tr>
                      <td colspan="4" class=" bg-white"></td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!userList && !userError">
                      <td colspan="4" class=" bg-white"><ContentPlaceholder /></td>
                    </tr>
                    <tr v-else-if="userError">
                      <td colspan="4" class=" bg-white">
                        <ErrorMessage />
                      </td>
                    </tr>
                    <tr v-else-if="!userList.length">
                      <td colspan="4" class="text-center bg-white">
                        <img src="@/assets/notfound.png" class="img-fluid ">
                        <div class="w-100 text-center">
                          <font-awesome-icon :icon="['fas', 'xmark']" class="me-2" />No users found
                        </div>
                      </td>

                    </tr>
                    <tr v-for="userItem in userList" :key="userItem.id">
                      <td class=" my-auto bg-white">
                        <router-link :to="{ name: 'adminUser', params: { userId: userItem.id } }" title="View user">
                          <span class=" text-dark">{{ userItem.firstName }} {{ userItem.lastName }}</span>
                        </router-link>
                      </td>
                      <td class=" my-auto bg-white">
                        <router-link :to="{ name: 'adminUser', params: { userId: userItem.id } }" title="View transaction">
                          <span v-if="userItem.representsCompany">Business</span>
                          <span v-else>Personal</span>
                        </router-link>
                      </td>
                      <td class=" my-auto bg-white">
                        <router-link :to="{ name: 'adminUser', params: { userId: userItem.id } }" title="View transaction">
                          <span class="text-success" v-if="userItem.paymentDetails && userItem.paymentDetails.mangopayDetails.kycLevel"><font-awesome-icon :icon="['fas', 'fa-check']" /></span>
                          <span v-else><font-awesome-icon :icon="['fas', 'fa-xmark']" /></span>
                        </router-link>
                      </td>
                      <td class=" my-auto bg-white">
                        <router-link :to="{ name: 'adminUser', params: { userId: userItem.id } }" title="View transaction">
                          <span class="text-success" v-if="userItem.paymentDetails && userItem.paymentDetails.mangopayDetails.kycLevel == 'REGULAR'"><font-awesome-icon :icon="['fas', 'fa-check']" /></span>
                          <span v-else><font-awesome-icon :icon="['fas', 'fa-xmark']" /></span>
                        </router-link>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  </main>
</template>

<script>
import FooterSection from '@/components/admin/Footer.vue'
import LoaderSection from '@/components/admin/LoadingSpinner.vue'
import NavigationSection from "@/components/admin/Navigation.vue";
import ContentPlaceholder from '@/components/admin/ContentPlaceholder.vue'
import ErrorMessage from '@/components/admin/ErrorMessage.vue'
import {
  userList,
  userError,
  userItem,
  getUserList,
  getMangopayStatusShort,
  getMangopayStatusLong
} from "@/utils/users.admin";
import {
  showInitials
} from "@/utils/support";
import {
  eftyIntegratorId
} from "@/utils/config";

export default {

  setup() {

    // Load users
    getUserList( eftyIntegratorId )

    // expose to template and other options API hooks
    return {
      getMangopayStatusShort,
      getMangopayStatusLong,
      userList,
      userItem,
      userError,
      showInitials
    }

  },
  name: 'AdminUserListView',
  components: { ErrorMessage, LoaderSection, FooterSection, NavigationSection, ContentPlaceholder }
}
</script>