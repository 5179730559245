<template>
  <nav class="navbar navbar-expand-lg navbar-dark pt-0 pt-lg-4 position-absolute top-0 w-100">
    <div class="container-fluid pe-0">
      <div class="row w-100">
        <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 px-0">

          <button class="navbar-toggler float-end border-0 mt-2" type="button" data-bs-toggle="collapse" data-bs-target="#mainMenu" aria-controls="mainMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>

          <router-link :to="{ name: 'publicHome' }" class="navbar-brand mb-3 d-lg-none"><img src="@/assets/logo-white.png" class="logo img-fluid mt-3"></router-link>

          <div class="collapse navbar-collapse px-4 pt-3 pt-lg-0 pe-0" id="mainMenu">

            <router-link :to="{ name: 'publicHome' }" class="navbar-brand mb-3"><img src="@/assets/logo-white.png" class="logo img-fluid mt-3"></router-link>

            <button class="navbar-toggler float-end mt-2 d-lg-none border-0 mob-closer" type="button" data-bs-toggle="collapse" data-bs-target="#mainMenu" aria-controls="mainMenu" aria-expanded="false" aria-label="Toggle navigation">
              <font-awesome-icon :icon="['fas', 'xmark']" />
            </button>

            <ul class="navbar-nav justify-content-center mt-3 mt-lg-0 ms-2 ms-lg-3">

              <li class="nav-item rounded ps-3 ps-lg-0" :class="{ active: currentRoute == 'publicHome' }">
                <router-link :to="{ name: 'publicHome' }" class="nav-link text-white">
                  <span class="nav-link-icon"><font-awesome-icon :icon="['fas', 'chart-line']" /></span>
                  <span class="nav-link-text text-white fw-bold">Dashboard</span>
                </router-link>
              </li>

              <li class="nav-item rounded me-2 ps-3 ps-lg-0" :class="{ active: currentRoute == 'publicTransactions' || currentRoute == 'publicTransaction' }">
                <router-link :to="{ name: 'publicTransactions' }" class="nav-link" aria-current="page">
                  <span class="nav-link-icon"><font-awesome-icon :icon="['far', 'fa-compass']" /></span>
                  <span class="nav-link-text text-white fw-bold">Transactions</span>
                </router-link>
              </li>

              <li class="nav-item rounded me-2 ps-3 ps-lg-0" :class="{ active: currentRoute == 'publicSupport' }">
                <router-link :to="{ name: 'publicSupport' }" class="nav-link text-white">
                  <span class="nav-link-icon"><font-awesome-icon :icon="['far', 'life-ring']" /></span>
                  <span class="nav-link-text text-white fw-bold">Get support</span>
                </router-link>
              </li>

              <li class="nav-item rounded ps-3 ps-lg-0" :class="{ active: currentRoute == 'publicSettings' }" v-if="onboardingStatus && onboardingStatus > 0">
                <router-link :to="{ name: 'publicSettings' }" class="nav-link text-white">
                  <span class="nav-link-icon"><font-awesome-icon :icon="['fas', 'cog']" /></span>
                  <span class="nav-link-text text-white fw-bold">Settings</span>
                </router-link>
              </li>

            </ul>

            <ul class="navbar-nav d-flex flex-fill justify-content-end mt-3 mt-lg-0 ms-2 ms-lg-0">
              <li class="nav-item float-end border-0 ps-3 ps-lg-0">

                <div role="button" @click="logOutOtp" class="nav-link text-white" >
                  <span class="nav-link-icon"><font-awesome-icon :icon="['fas', 'arrow-right-from-bracket']" /></span>
                  <span class="nav-link-text text-white fw-bold fs6">Sign out</span>
                </div>

              </li>
          </ul>


          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>

import {getOtpUserId, getOtpUserProfile, otpSignOut, otpUserProfile} from "@/utils/magiclink";
import {router} from "@/router";
import {ref, watch} from "vue";
import {getOnboardingStatus, onboardingStatus} from "@/utils/onboarding"

export default {
  name: 'NavigationSection',
  setup() {

    const currentRoute = ref(0)

    getOtpUserId()
    getOtpUserProfile()

    const logOutOtp = async () => {

      await otpSignOut()

      // Redirect to homepage
      router.push({ path: '/public', replace: true })
    }

    currentRoute.value = router.currentRoute.value.name

    watch(otpUserProfile, () => {

      getOnboardingStatus(otpUserProfile.value)

    });

    return {
      logOutOtp,
      currentRoute,
      onboardingStatus
    }
  }

};
</script>