<template>
  <NavigationSection />
  <LoaderSection v-show="showLoadingSpinner" />
  <main ref="root">
    <div class="main-content-wrapper w-100"><div class="container-fluid">
      <div class="row">
        <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
          <div class="main-content p-3 pt-4">

            <h1 class="fw-bold h3 text-dark" v-if="userItem"><font-awesome-icon :icon="['far', 'fa-address-book']" />{{ userItem.firstName }} {{ userItem.lastName }}</h1>

            <div class="clearfix mt-3">
              <nav aria-label="breadcrumb" class="float-start d-inline-block">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><router-link :to="{ name: 'adminHome' }" class="text-dark">Efty Pay</router-link></li>
                  <li class="breadcrumb-item"><router-link :to="{ name: 'adminUsers' }" class="text-dark">Users</router-link></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <span v-if="userItem">{{ userItem.firstName }} {{ userItem.lastName }}</span>
                    <span v-else-if="userError">Not found</span>
                  </li>
                </ol>
              </nav>
            </div>

            <div v-if="!userItem && !userError">
              <ContentPlaceholder />
            </div>
            <div v-else-if="userError">
              <ErrorMessage />
            </div>

            <div v-else-if="!userItem.length">

              <ul class="nav nav-tabs mt-4" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="transaction-tab" data-bs-toggle="tab" data-bs-target="#user-details" type="button" role="tab" aria-controls="user-details" aria-selected="true"><font-awesome-icon :icon="['far', 'fa-address-book']" />User details</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="mangopay-tab" data-bs-toggle="tab" data-bs-target="#mangopay-details" type="button" role="tab" aria-controls="mangopay-details" aria-selected="true"><font-awesome-icon :icon="['far', 'fa-credit-card']" class="me-2" />Mangopay</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="transaction-tab" data-bs-toggle="tab" data-bs-target="#transaction-details" type="button" role="tab" aria-controls="transaction-details" aria-selected="true"><font-awesome-icon :icon="['far', 'fa-compass']" class="me-2" />Transactions</button>
                </li>
              </ul>

              <div class="tab-content">

                <!-- User details -->
                <div class="tab-pane fade show active" id="user-details" role="tabpanel" aria-labelledby="user-details-tab">
                  <div class="card border border-top-0 rounded-0">
                    <div class="card-body bg-white">
                      <div class="row">
                        <div class="col-12 col-lg-8">

                          <div class="row mb-2">
                            <div class="col-4 fw-bold text-muted">
                              Name
                            </div>
                            <div class="col-8 text-success fw-bold">
                              {{ userItem.firstName }} {{ userItem.lastName }}
                            </div>

                          </div>

                          <div class="row mb-2">
                            <div class="col-4 fw-bold text-muted">
                              E-mail
                            </div>
                            <div class="col-8">
                              {{ userItem.email }}
                            </div>
                          </div>

                          <div class="row mb-2">
                            <div class="col-4 fw-bold text-muted">
                              Username
                            </div>
                            <div class="col-8">
                              {{ userItem.username }}
                            </div>
                          </div>

                          <div class="row mb-2">
                            <div class="col-4 fw-bold text-muted">
                              User Type
                            </div>
                            <div class="col-8">
                              {{ userItem.userType }}
                            </div>
                          </div>

                          <div class="row mb-2">
                            <div class="col-4 fw-bold text-muted">
                              Phone number
                            </div>
                            <div class="col-8">
                              {{ userItem.phoneNumber }}
                            </div>
                          </div>

                          <div class="row mb-2">
                            <div class="col-4 fw-bold text-muted">
                              Website
                            </div>
                            <div class="col-8">
                              {{ userItem.website }}
                            </div>
                          </div>

                          <div class="row mb-2" v-if="mangopayOnboardingItem.naturalUser">
                            <div class="col-4 fw-bold text-muted">
                              Date of Birth
                            </div>
                            <div class="col-8">
                              {{ dayjs(mangopayOnboardingItem.naturalUser.dateOfBirth).format('YYYY-MM-DD')  }}
                            </div>
                          </div>

                          <div class="row mb-2" v-if="mangopayOnboardingItem.legalUser">
                            <div class="col-4 fw-bold text-muted">
                              Address
                            </div>
                            <div class="col-8">
                              <div v-if="userItem.legalUser.registeredAddress">
                                <div>{{ userItem.legalUser.registeredAddress.addressLine1 }}</div>
                                <div v-if="userItem.legalUser.registeredAddress.addressLine2">{{ userItem.legalUser.registeredAddress.addressLine2 }}</div>
                                <div>{{ userItem.legalUser.registeredAddress.postalCode }} {{ userItem.legalUser.registeredAddress.city }}</div>
                                <div>{{ userItem.legalUser.registeredAddress.country }}</div>
                              </div>
                            </div>
                          </div>

                          <div class="row mb-2">
                            <div class="col-4 fw-bold text-muted">
                              Company
                            </div>
                            <div class="col-8">
                              <span v-if="userItem.representsCompany">Yes</span>
                              <span v-else>No</span>
                            </div>
                          </div>

                          <div class="row mb-2" v-if="userItem.representsCompany">
                            <div class="col-4 fw-bold text-muted">
                              Company name
                            </div>
                            <div class="col-8">
                              {{ userItem.companyName }}
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>


                </div>

                <!-- Onboarding details -->
                <div class="tab-pane fade show" id="mangopay-details" role="tabpanel" aria-labelledby="mangopay-details-tab">
                  <div class="card border border-top-0 rounded-0">
                    <div class="card-body bg-white">
                      <div class="row">
                        <div class="col-12 col-lg-8">

                          <div class="row mb-2">

                            <div class="col-4 fw-bold text-muted">
                              Status
                            </div>

                            <div class="col-8">
                              <span class="fw-bold">{{ getMangopayStatusShort(userItem.paymentDetails.mangopayDetails.kycLevel) }}</span><br>
                              <span class="text-grey">{{ getMangopayStatusLong(userItem.paymentDetails.mangopayDetails.kycLevel) }}</span>
                            </div>

                          </div>

                          <div class="row mb-2">

                            <div class="col-4 fw-bold text-muted">
                              User account
                            </div>

                            <div class="col-8">
                              <span class="text-success" v-if="userItem.paymentDetails.mangopayDetails.kycLevel"><font-awesome-icon :icon="['fas', 'fa-check']" /></span>
                              <span v-else><font-awesome-icon :icon="['fas', 'fa-xmark']" /></span>
                            </div>

                          </div>

                          <div class="row mb-2">

                            <div class="col-4 fw-bold text-muted">
                              Verified
                            </div>

                            <div class="col-8">
                              <span class="text-success" v-if="userItem.paymentDetails.mangopayDetails.kycLevel == 'REGULAR'"><font-awesome-icon :icon="['fas', 'fa-check']" /></span>
                              <span v-else><font-awesome-icon :icon="['fas', 'fa-xmark']" /></span>
                            </div>

                          </div>

                          <div class="row mb-2">

                            <div class="col-4 fw-bold text-muted">
                              User ID
                            </div>

                            <div class="col-8">
                              {{ userItem.paymentDetails.mangopayDetails.userId }}
                              <a v-if="userItem.paymentDetails.mangopayDetails.userId" :href="'https://dashboard.mangopay.com/User/' + userItem.paymentDetails.mangopayDetails.userId + '/Details'" target="_blank"><font-awesome-icon :icon="['fas', 'external-link-alt']" /></a>
                              <span v-else><font-awesome-icon :icon="['fas', 'fa-xmark']" /></span>
                            </div>

                          </div>

                          <div class="row mb-2" v-if="mangopayOnboardingItem.legalUser">

                            <div class="col-4 fw-bold text-muted">
                              UBO
                            </div>

                            <div class="col-8">

                              <div v-if="mangopayOnboardingItem.legalUser.uboDeclaration">
                                <div v-for="(beneficialOwner) in mangopayOnboardingItem.legalUser.uboDeclaration.beneficialOwners" :key="beneficialOwner.id">
                                  <div class="card bg-light shadow-sm mb-3">

                                    <div class="card-body">
                                      <div class="row">

                                        <div class="col-12 col-lg-6">

                                          <div class="container-fluid">

                                            <div class="row">
                                              <div class="col-6 px-0">
                                                <h3>{{ beneficialOwner.firstName }} {{ beneficialOwner.lastName }}</h3>
                                              </div>


                                            </div>

                                          </div>

                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <span><font-awesome-icon :icon="['fas', 'fa-xmark']" /></span>
                              </div>

                            </div>

                          </div>

                          <div class="row mb-2">

                            <div class="col-4 fw-bold text-muted">
                              Documents
                            </div>

                            <div class="col-8">
                              <div v-if="mangopayOnboardingItem.associatedKycDocumentDetails">
                                <div class="card bg-light shadow-sm mb-3 p-3" v-for="(onboardingDocument) in mangopayOnboardingItem.associatedKycDocumentDetails" :key="onboardingDocument.mangopayDocumentId">
                                  <h2 class="fw-bold h6">
                                    {{ onboardingDocument.documentType }}
                                    <span v-if="onboardingDocument.status == 'VALIDATION_ASKED' || onboardingDocument.status == 'CREATED'" class="badge bg-warning"><font-awesome-icon :icon="['fas', 'hourglass']" class="me-2"/>{{ onboardingDocument.status }}</span>
                                    <span v-else-if="onboardingDocument.status == 'REFUSED' || onboardingDocument.status == 'OUT_OF_DATE'" class="badge bg-danger"><font-awesome-icon :icon="['fas', 'circle-exclamation']" class="me-2"/>{{ onboardingDocument.status }}</span>
                                    <span v-else-if="onboardingDocument.status == 'VALIDATED'" class="badge bg-success"><font-awesome-icon :icon="['fas', 'check']" class="me-2"/>{{ onboardingDocument.status }}</span>
                                  </h2>
                                  <span>Created {{ dayjs(onboardingDocument.created).format('YYYY-MM-DD HH:mm')  }}</span>
                                  <div class="alert bg-danger mt-2" v-if="onboardingDocument.status == 'REFUSED'">
                                    <p class="small text-white mb-0">This document has not been accepted with the following reason: '{{ onboardingDocument.refusedReasonType }}'</p>
                                    <p class="small mb-0 text-white mt-3">Please correct the errors and re-upload the document.</p>
                                  </div>
                                </div>
                              </div>
                              <div v-else>
                                <span><font-awesome-icon :icon="['fas', 'fa-xmark']" /></span>
                              </div>

                            </div>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Transaction details -->
                <div class="tab-pane fade show" id="transaction-details" role="tabpanel" aria-labelledby="transaction-details-tab">
                  <div class="card border border-top-0 rounded-0">
                    <div class="card-body bg-white">
                      <div class="row">
                        <div class="col-12">
                          <img src="@/assets/notfound.png" class="img-fluid ">
                          <div class="">
                            <font-awesome-icon :icon="['fas', 'xmark']" class="ms-4" />No transactions found
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <FooterSection />
    </div>
  </main>
</template>

<script>
import FooterSection from '@/components/admin/Footer.vue'
import NavigationSection from '@/components/admin/Navigation.vue'
import LoaderSection from "@/components/public/LoadingSpinner.vue";
import {getUserItem, userItem,
  getMangopayStatusShort,
  getMangopayStatusLong} from "@/utils/users.admin";
import {getMangopayOnboardingStatus, mangopayOnboardingItem} from "@/utils/mangopay.onboarding.public";
import {eftyIntegratorId} from "@/utils/environment.config";
import {useRoute} from "vue-router";
import dayjs from "dayjs";

export default {

  inheritAttrs: true,

  mounted() {

    // Use router to access the transaction ID
    const route = useRoute();

    // Load user item (prop userId is passed by the router)
    getUserItem( route.params.userId, eftyIntegratorId )
    getMangopayOnboardingStatus( route.params.userId, eftyIntegratorId )

  },

  setup() {


    // expose to template and other options API hooks
    return {
      userItem,
      getMangopayStatusShort,
      getMangopayStatusLong,
      mangopayOnboardingItem,
      dayjs,
    }

  },
  name: 'AdminUserItemView',
  components: { NavigationSection, LoaderSection, FooterSection }
}
</script>