import { ref } from 'vue'
import {apiEndpoint,eftyIntegratorId,eftyPayAdminId} from '@/utils/environment.config'

// Set list with all transaction statusses
const transactionStatusList = ref(0)
const transactionSubStatusList = ref(0)
const isoCountriesList = ref(0)
const checkoutSteps = ref(0)
const onboardingStatusList = ref(0)
const onboardingDocumentTypeList = ref(0)
const onboardingDocumentStatusList = ref(0)
const onboardingDocumenRefusedTypeList= ref(0)
const allowedIdDocumentTypes = ref(0)
const descriptionForDocumentTypes = ref(0)

// Threshold for credit card payments, payments that exceed this amount will only be payable via bank transfer
const thresholdForCreditCardPayments = {
    EUR: 2500,
    USD: 3300,
    GBP: 1800
};

const userRoleList = ref(0)

// Set list with user roles (made unrecognizable for local storage)
userRoleList.value = [
    { code: 'j29LDyjiK3tJfVvBKF4uQb3q', name: 'admin', desc: 'Efty Pay admin users'},
    { code: '6U1dhU2kSMdo4sz5mLjuiK3u', name: 'user', desc: 'Efty Pay users (buyers and sellers)'}]

// List with all known statusses for a transaction
transactionStatusList.value = [
    { name: 'TRANSACTION_NONE', title: 'No Status', faGroup: 'fas', faIcon: 'ban', enum: 0},
    { name: 'INITIATE_TRANSACTION', title: 'Transaction creation', faGroup: 'fas', faIcon: 'check', enum: 1},
    { name: 'PAYMENT_TO_EFTY_PAY', title: 'Payment to Efty Pay', faGroup: 'far', faIcon: 'credit-card', enum: 2},
    { name: 'TRANSFER_ASSET_TO_EFTY_PAY', title: 'Domain transfer to Efty Pay', faGroup: 'fas', faIcon: 'right-left', enum: 3},
    { name: 'TRANSFER_ASSET_TO_BUYER', title: 'Domain transfer to buyer', faGroup: 'fas', faIcon: 'right-left', enum: 5},
    { name: 'PAYOUT_TO_SELLER', title: 'Payout to seller', faGroup: 'fas', faIcon: 'money-bill-transfer', enum: 4},
    { name: 'TRANSACTION_COMPLETE_SELLER', title: 'Transaction completed', faGroup: 'fas', faIcon: 'flag-checkered', enum: 6},
    { name: 'TRANSACTION_COMPLETE_BUYER', title: 'Transaction completed', faGroup: 'fas', faIcon: 'flag-checkered', enum: 7},
    { name: 'TRANSACTION_CANCELLED', title: 'Transaction cancelled', faGroup: 'fas', faIcon: 'ban', enum: 8},
    { name: 'TRANSACTION_COMPLETE', title: 'Transaction completed', faGroup: 'fas', faIcon: 'ban', enum: 9}] // Added this one to match the statusses from the endpoint

// List with all known substatusses for a transaction
transactionSubStatusList.value = [
    { name: 'SUB_STATUS_NONE', title: 'No substatus', enum: 0},
    { name: 'INITIATED_OR_REQUESTED', title: 'Initiated', enum: 1},
    { name: 'CONFIRMED', title: 'Confirmed', enum: 2},
    { name: 'APPROVED', title: 'Approved', enum: 3}]

// List with all steps in checkout proces
checkoutSteps.value = [
    { name: 'BILLING_DETAILS', title: 'Biling details', enum: 0},
    { name: 'PAYMENT_METHOD', title: 'Payment method', enum: 1},
    { name: 'CONFIRMATION', title: 'Confirm', enum: 2},
    { name: 'TRANSFER', title: 'Payment', enum: 3}]

// List with all known substatusses for an onboarding
onboardingStatusList.value = [
    { name: 'STATUS_NONE', title: 'None', enum: 0},
    { name: 'DETAILS', title: 'Seller details', enum: 1},
    { name: 'DOCUMENTS', title: 'Legal documents', enum: 2},
    { name: 'OWNERS', title: 'Beneficial owners', enum: 3},
    { name: 'COMPLETE', title: 'Ready to sell domains', enum: 4},
    { name: 'COMPLETE_FINANCIAL', title: 'Onboarding complete', enum: 5}]

// List with all known documents for onboarding
onboardingDocumentTypeList.value = [
    { name: 'KYC_DOCUMENT_TYPE_NONE', title: 'No document type', allow_upload: false},
    { name: 'IDENTITY_PROOF', title: 'Proof of identity', enum: 1, allow_upload: true},
    { name: 'REGISTRATION_PROOF', title: 'Proof of registration', enum: 2, allow_upload: true},
    { name: 'ARTICLES_OF_ASSOCIATION', title: 'Articles of Association', enum: 3, allow_upload: true},
    { name: 'SHAREHOLDER_DECLARATION', title: 'Shareholder declaration', enum: 4, allow_upload: true},
    { name: 'ADDRESS_PROOF', title: 'Proof of address', enum: 5, allow_upload: true},
    { name: 'PASSPORT', title: 'Passport', enum: 6, allow_upload: false},
    { name: 'DRIVING_LICENSE', title: 'Driving License', enum: 7, allow_upload: false},
    { name: 'NATIONAL_IDENTITY_CARD', title: 'National Identity Card', enum: 8, allow_upload: false},
    { name: 'ID_CARD', title: 'ID Card', enum: 9, allow_upload: false},
    { name: 'RESIDENCE_PERMIT', title: 'Residence permit', enum: 10, allow_upload: false},
    { name: 'HEALTH_INSURANCE_CARD', title: 'Health insurance card', enum: 11, allow_upload: false}]


// List with all known documents for onboarding
onboardingDocumenRefusedTypeList.value = [
{ name: 'DOCUMENT_FALSIFIED', title: 'The document is not accepted; it doesn\'t fit the verification requirements outlined by us.'}, /*'The document seems to be fraudulent or contains inconsistent information.'*/
{ name: 'DOCUMENT_HAS_EXPIRED', title: 'The document has passed its expiry date; it is no longer valid. Submit an in-date accepted identity proof.'},
{ name: 'DOCUMENT_NOT_ACCEPTED', title: 'The document is not accepted; it doesn\'t fit the verification requirements outlined by us.'},
{ name: 'DOCUMENT_UNREADABLE', title: 'The document provided is not clear enough. Check the clarity and readability of your file before re-submitting the identity proof.'},
{ name: 'DOCUMENT_INCOMPLETE', title: 'The document is incomplete, for example the back of the identity card is missing. Check that you upload the correct file and that it contains both sides of the identity proof (if applicable).'},
{ name: 'DOCUMENT_DO_NOT_MATCH_USER_DATA', title: 'The individual indicated on the document doesn’t correspond to the one registered. Check that you upload the correct file and that it contains the identity proof of the user.'},
{ name: 'UNDERAGE_PERSON', title: 'The individual indicated on the document is under 18.'},
{ name: 'DOCUMENT_MISSING', title: 'The document is missing from the image or the file.'}]

// List with all known documents for onboarding
onboardingDocumentStatusList.value = [
    { name: 'CREATED', title: 'Uploaded, needs to be submitted'},
    { name: 'VALIDATION_ASKED', title: 'Waiting for validation'},
    { name: 'VALIDATED', title: 'Validated'},
    { name: 'REFUSED', title: 'Declined'},
    { name: 'OUT_OF_DATE', title: 'Out of date'}]

descriptionForDocumentTypes.value = [
    {
        code: "FR",
        REGISTRATION_PROOF: "An official extract from the Trade and Companies Register (Extrait Kbis), issued within the last 3 months, detailing the legal status, address, and identity of the company representatives.",
        ARTICLES_OF_ASSOCIATION: "The latest signed version of the Statuts de la société, outlining the company's structure, rules, and regulations."
    },
    {
        code: "GR",
        REGISTRATION_PROOF: "A Certificate of Incorporation from the General Commercial Registry (G.E.MI.), issued within the last 3 months, including information on the company's legal form, registered address, and management.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Καταστατικό εταιρείας, specifying the partnership agreements and the internal regulations of the company."
    },
    {
        code: "NO",
        REGISTRATION_PROOF: "A Certificate of Registration from the Brønnøysund Register Centre, not older than 3 months, containing details about the company's registration number, address, and business activities.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Vedtekter, detailing the company's articles of association, including management and operational guidelines."
    },
    {
        code: "SE",
        REGISTRATION_PROOF: "A Certificate of Registration from the Swedish Companies Registration Office (Bolagsverket), issued within the last 3 months, listing the company’s registration number, address, and statutory representatives.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Bolagsordning, describing the company’s statutes and regulatory framework."
    },
    {
        code: "DE",
        REGISTRATION_PROOF: "An extract from the Commercial Register (Handelsregisterauszug), not older than 3 months, providing details on the company's registration, legal form, and executive bodies.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Gesellschaftsvertrag, outlining the company’s constitution, governance structures, and shareholder agreements."
    },
    {
        code: "AT",
        REGISTRATION_PROOF: "An extract from the Firmenbuchauszug Registry, not older than 3 months, specifying the company's registration number, legal status, and registered office.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Gesellschaftsvertrag, detailing the formation, structure, and governance of the company."
    },
    {
        code: "NL",
        REGISTRATION_PROOF: "An extract from the Chamber of Commerce (Kamer van Koophandel), issued within the last 3 months, including the company's registration details, legal form, and statutory seat.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Statuten, specifying the company's articles of association, including governance and operational procedures."
    },
    {
        code: "FI",
        REGISTRATION_PROOF: "An extract from the Trade Register (Kaupparekisteri), not older than 3 months, detailing the company's registration number, legal form, and business activities.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Yhtiöjärjestys, outlining the company’s corporate governance and internal regulations."
    },
    {
        code: "DK",
        REGISTRATION_PROOF: "A Certificate of Registration from the Danish Business Authority (Erhvervsstyrelsen), issued within the last 3 months, providing details on the company’s legal status, address, and management.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Vedtægter, specifying the company’s statutes, including management structures and operational rules."
    },
    {
        code: "IE",
        REGISTRATION_PROOF: "A Certificate of Incorporation from the Companies Registration Office, not older than 3 months, listing the company’s registration number, legal form, and registered office.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Constitution, detailing the company’s rules, regulations, and internal governance structures."
    },
    {
        code: "BE",
        REGISTRATION_PROOF: "An extract from the Crossroads Bank for Enterprises (Banque-Carrefour des Entreprises/Kruispuntbank van Ondernemingen), issued within the last 3 months, including the company's registration number, legal form, and registered address.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Statuten van de vennootschap, outlining the company’s statutes, including its governance and operational framework."
    },
    {
        code: "BG",
        REGISTRATION_PROOF: "A Certificate of Good Standing from the Commercial Register, not older than 3 months, providing information on the company's legal status, address, and executive officers.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Устав, detailing the company's articles of association, including management and shareholder agreements."
    },
    {
        code: "HR",
        REGISTRATION_PROOF: "An extract from the Court Register, issued within the last 3 months, specifying the company's registration details, legal form, and statutory representatives.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Statut društva, outlining the company’s constitution and governance structures."
    },
    {
        code: "CY",
        REGISTRATION_PROOF: "A Certificate of Registration from the Department of Registrar of Companies and Official Receiver, not older than 3 months, including the company's registration number, legal form, and registered office.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Σύσταση Εταιρείας, detailing the company's articles of association, including governance and operational rules."
    },
    {
        code: "CZ",
        REGISTRATION_PROOF: "An extract from the Commercial Register (Výpis z obchodního rejstříku), issued within the last 3 months, providing details on the company’s registration, legal form, and statutory representatives.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Stanovy společnosti, outlining the company’s statutes and internal regulations."
    },
    {
        code: "EE",
        REGISTRATION_PROOF: "An extract from the Commercial Register (Äriregistri väljavõte), not older than 3 months, detailing the company’s registration number, legal form, and executive bodies.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Põhikiri, specifying the company’s articles of association and internal governance rules."
    },
    {
        code: "HU",
        REGISTRATION_PROOF: "A Certificate of Incorporation from the Hungarian Court of Registration, issued within the last 3 months, providing information on the company's registration number, legal form, and registered office.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Alapító Okirat, detailing the company's formation, structure, and governance."
    },
    {
        code: "IS",
        REGISTRATION_PROOF: "A Certificate of Registration from the Register of Enterprises (Fyrirtækjaskrá), not older than 3 months, including the company's registration number, legal form, and statutory seat.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Samþykktir félagsins, outlining the company’s statutes and internal regulations."
    },
    {
        code: "IT",
        REGISTRATION_PROOF: "A Certificate of Incorporation from the Business Register (Registro delle Imprese), issued within the last 3 months, providing details on the company’s legal form, registered office, and statutory representatives.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Statuto della società, specifying the company’s articles of association and governance framework."
    },
    {
        code: "LV",
        REGISTRATION_PROOF: "A Certificate of Registration from the Commercial Register, not older than 3 months, detailing the company’s registration number, legal form, and statutory seat.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Uzņēmuma statūti, outlining the company’s internal regulations and governance structures."
    },
    {
        code: "LI",
        REGISTRATION_PROOF: "An extract from the Commercial Register, issued within the last 3 months, including details on the company’s registration, legal form, and statutory representatives.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Gesellschaftsvertrag, specifying the company’s constitution and governance framework."
    },
    {
        code: "LT",
        REGISTRATION_PROOF: "A Certificate of Incorporation from the Register of Legal Entities, not older than 3 months, providing information on the company's registration number, legal form, and registered office.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Įstatai, detailing the company’s articles of association and internal governance."
    },
    {
        code: "LU",
        REGISTRATION_PROOF: "A Certificate of Registration from the Luxembourg Business Register (Registre de Commerce et des Sociétés), issued within the last 3 months, providing details on the company’s registration number, legal form, and statutory representatives.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Statuts, outlining the company’s internal regulations and governance framework."
    },
    {
        code: "MT",
        REGISTRATION_PROOF: "A Certificate of Registration from the Malta Business Registry, not older than 3 months, detailing the company’s registration number, legal form, and registered office.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Memorandum and Articles of Association, specifying the company’s statutes and governance framework."
    },
    {
        code: "PL",
        REGISTRATION_PROOF: "An extract from the National Court Register (Krajowy Rejestr Sądowy), issued within the last 3 months, including details on the company’s registration number, legal form, and statutory representatives.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Statut, outlining the company’s articles of association and internal governance."
    },
    {
        code: "PT",
        REGISTRATION_PROOF: "A Certificate of Registration from the Portuguese Commercial Registry, not older than 3 months, providing information on the company's registration number, legal form, and statutory seat.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Estatutos, detailing the company’s internal regulations and governance framework."
    },
    {
        code: "RO",
        REGISTRATION_PROOF: "A Certificate of Registration from the Trade Register, issued within the last 3 months, providing details on the company’s registration number, legal form, and statutory representatives.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Act constitutiv, specifying the company’s articles of association and governance framework."
    },
    {
        code: "SK",
        REGISTRATION_PROOF: "An extract from the Commercial Register (Výpis z Obchodného Registra), not older than 3 months, including information on the company’s registration number, legal form, and statutory seat.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Spoločenská zmluva, outlining the company’s internal regulations and governance framework."
    },
    {
        code: "SI",
        REGISTRATION_PROOF: "An extract from the Business Register (Poslovni register Slovenije), issued within the last 3 months, providing details on the company’s registration number, legal form, and statutory representatives.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Družbena pogodba, specifying the company’s articles of association and internal governance."
    },
    {
        code: "ES",
        REGISTRATION_PROOF: "A Certificate of Registration from the Mercantile Register (Registro Mercantil), not older than 3 months, detailing the company’s registration number, legal form, and registered office.",
        ARTICLES_OF_ASSOCIATION: "The latest signed Escritura de constitución, outlining the company’s internal regulations and governance framework."
    },
    {
        code: "default",
        REGISTRATION_PROOF: "Certificate of Incorporation or equivalent document, not older than 3 months, detailing the company's legal status, address, and identity of the company representatives.",
        ARTICLES_OF_ASSOCIATION: "Articles of Association or equivalent document, outlining the company's structure, rules, and regulations."
    }
];

// List with allowed ID document types per country (https://mangopay.com/docs/concepts/users/verification/requirements-natural)
allowedIdDocumentTypes.value = {
    FR: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    GR: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    NO: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD"],
    SE: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT", "TAX_ID"],
    // Other EEA countries
    AT: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    BE: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    BG: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    HR: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    CY: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    CZ: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    DK: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    EE: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    FI: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    DE: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    HU: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    IS: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    IE: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    IT: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    LV: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    LI: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    LT: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    LU: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    MT: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    NL: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    PL: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    PT: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    RO: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    SK: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    SI: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    ES: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD", "RESIDENCE_PERMIT"],
    // Countries outside the EEA
    ID: ["PASSPORT", "ID_CARD", "DRIVING_LICENSE", "RESIDENCE_PERMIT"],
    PH: ["PASSPORT", "HEALTH_INSURANCE_CARD"],
    CH: ["PASSPORT", "RESIDENCE_PERMIT"],
    GB: ["PASSPORT", "DRIVING_LICENSE"],
    US: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD"],
    CA: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD"],
    AU: ["PASSPORT", "DRIVING_LICENSE", "ID_CARD"],
    // Other countries outside the EEA
    default: ["PASSPORT"]
}

// List with all country codes and names based on ISO
isoCountriesList.value = [
    { code: "AF", name: "Afghanistan" },
    { code: "AL", name: "Albania" },
    { code: "DZ", name: "Algeria" },
    { code: "AS", name: "American Samoa" },
    { code: "AD", name: "Andorra" },
    { code: "AO", name: "Angola" },
    { code: "AI", name: "Anguilla" },
    { code: "AQ", name: "Antarctica" },
    { code: "AG", name: "Antigua and Barbuda" },
    { code: "AR", name: "Argentina" },
    { code: "AM", name: "Armenia" },
    { code: "AW", name: "Aruba" },
    { code: "AU", name: "Australia" },
    { code: "AT", name: "Austria", in_eea: true },
    { code: "AZ", name: "Azerbaijan" },
    { code: "BS", name: "Bahamas" },
    { code: "BH", name: "Bahrain" },
    { code: "BD", name: "Bangladesh" },
    { code: "BB", name: "Barbados" },
    { code: "BY", name: "Belarus" },
    { code: "BE", name: "Belgium", in_eea: true },
    { code: "BZ", name: "Belize" },
    { code: "BJ", name: "Benin" },
    { code: "BM", name: "Bermuda" },
    { code: "BT", name: "Bhutan" },
    { code: "BO", name: "Bolivia" },
    { code: "BA", name: "Bosnia and Herzegovina" },
    { code: "BW", name: "Botswana" },
    { code: "BV", name: "Bouvet Island", blocked: true },
    { code: "BR", name: "Brazil" },
    { code: "IO", name: "British Indian Ocean Territory" },
    { code: "BN", name: "Brunei Darussalam" },
    { code: "BG", name: "Bulgaria", in_eea: true },
    { code: "BF", name: "Burkina Faso" },
    { code: "BI", name: "Burundi" },
    { code: "KH", name: "Cambodia" },
    { code: "CM", name: "Cameroon" },
    { code: "CA", name: "Canada" },
    { code: "CV", name: "Cape Verde" },
    { code: "KY", name: "Cayman Islands" },
    { code: "CF", name: "Central African Republic" },
    { code: "TD", name: "Chad" },
    { code: "CL", name: "Chile" },
    { code: "CN", name: "China" },
    { code: "CX", name: "Christmas Island" },
    { code: "CC", name: "Cocos (Keeling) Islands" },
    { code: "CO", name: "Colombia" },
    { code: "KM", name: "Comoros" },
    { code: "CG", name: "Congo" },
    { code: "CD", name: "Congo, The Democratic Republic of The" },
    { code: "CK", name: "Cook Islands" },
    { code: "CR", name: "Costa Rica" },
    { code: "CI", name: "Cote D'ivoire" },
    { code: "HR", name: "Croatia", in_eea: true },
    { code: "CU", name: "Cuba", blocked: true },
    { code: "CY", name: "Cyprus", in_eea: true },
    { code: "CZ", name: "Czech Republic", in_eea: true },
    { code: "DK", name: "Denmark", in_eea: true },
    { code: "DJ", name: "Djibouti" },
    { code: "DM", name: "Dominica" },
    { code: "DO", name: "Dominican Republic" },
    { code: "EC", name: "Ecuador" },
    { code: "EG", name: "Egypt" },
    { code: "SV", name: "El Salvador" },
    { code: "GQ", name: "Equatorial Guinea" },
    { code: "ER", name: "Eritrea" },
    { code: "EE", name: "Estonia", in_eea: true },
    { code: "ET", name: "Ethiopia" },
    { code: "FK", name: "Falkland Islands (Malvinas)" },
    { code: "FO", name: "Faroe Islands" },
    { code: "FJ", name: "Fiji" },
    { code: "FI", name: "Finland", in_eea: true },
    { code: "FR", name: "France", in_eea: true },
    { code: "GF", name: "French Guiana" },
    { code: "PF", name: "French Polynesia" },
    { code: "TF", name: "French Southern Territories" },
    { code: "GA", name: "Gabon" },
    { code: "GM", name: "Gambia" },
    { code: "GE", name: "Georgia" },
    { code: "DE", name: "Germany", in_eea: true },
    { code: "GH", name: "Ghana" },
    { code: "GI", name: "Gibraltar" },
    { code: "GR", name: "Greece", in_eea: true },
    { code: "GL", name: "Greenland" },
    { code: "GD", name: "Grenada" },
    { code: "GP", name: "Guadeloupe" },
    { code: "GU", name: "Guam" },
    { code: "GT", name: "Guatemala" },
    { code: "GG", name: "Guernsey" },
    { code: "GN", name: "Guinea" },
    { code: "GW", name: "Guinea-bissau" },
    { code: "GY", name: "Guyana" },
    { code: "HT", name: "Haiti" },
    { code: "HM", name: "Heard Island and Mcdonald Islands", blocked: true },
    { code: "VA", name: "Holy See (Vatican City State)" },
    { code: "HN", name: "Honduras" },
    { code: "HK", name: "Hong Kong" },
    { code: "HU", name: "Hungary", in_eea: true },
    { code: "IS", name: "Iceland", in_eea: true },
    { code: "IN", name: "India" },
    { code: "ID", name: "Indonesia" },
    { code: "IR", name: "Iran, Islamic Republic of", blocked: true },
    { code: "IQ", name: "Iraq", blocked: true },
    { code: "IE", name: "Ireland", in_eea: true },
    { code: "IM", name: "Isle of Man" },
    { code: "IL", name: "Israel" },
    { code: "IT", name: "Italy", in_eea: true },
    { code: "JM", name: "Jamaica" },
    { code: "JP", name: "Japan" },
    { code: "JE", name: "Jersey" },
    { code: "JO", name: "Jordan" },
    { code: "KZ", name: "Kazakhstan" },
    { code: "KE", name: "Kenya" },
    { code: "KI", name: "Kiribati" },
    { code: "KP", name: "Korea, Democratic People's Republic of", blocked: true },
    { code: "KR", name: "Korea, Republic of" },
    { code: "KW", name: "Kuwait" },
    { code: "KG", name: "Kyrgyzstan" },
    { code: "LA", name: "Lao People's Democratic Republic" },
    { code: "LV", name: "Latvia", in_eea: true },
    { code: "LB", name: "Lebanon" },
    { code: "LS", name: "Lesotho" },
    { code: "LR", name: "Liberia" },
    { code: "LY", name: "Libyan Arab Jamahiriya" },
    { code: "LI", name: "Liechtenstein", in_eea: true },
    { code: "LT", name: "Lithuania", in_eea: true },
    { code: "LU", name: "Luxembourg", in_eea: true },
    { code: "MO", name: "Macao" },
    { code: "MK", name: "Macedonia, The Former Yugoslav Republic of" },
    { code: "MG", name: "Madagascar" },
    { code: "MW", name: "Malawi" },
    { code: "MY", name: "Malaysia" },
    { code: "MV", name: "Maldives" },
    { code: "ML", name: "Mali" },
    { code: "MT", name: "Malta", in_eea: true },
    { code: "MH", name: "Marshall Islands" },
    { code: "MQ", name: "Martinique" },
    { code: "MR", name: "Mauritania" },
    { code: "MU", name: "Mauritius" },
    { code: "YT", name: "Mayotte" },
    { code: "MX", name: "Mexico" },
    { code: "FM", name: "Micronesia, Federated States of" },
    { code: "MD", name: "Moldova, Republic of" },
    { code: "MC", name: "Monaco" },
    { code: "MN", name: "Mongolia" },
    { code: "ME", name: "Montenegro" },
    { code: "MS", name: "Montserrat" },
    { code: "MA", name: "Morocco" },
    { code: "MZ", name: "Mozambique" },
    { code: "MM", name: "Myanmar", blocked: true },
    { code: "NA", name: "Namibia" },
    { code: "NR", name: "Nauru" },
    { code: "NP", name: "Nepal" },
    { code: "NL", name: "Netherlands", in_eea: true },
    { code: "AN", name: "Netherlands Antilles", blocked: true },
    { code: "NC", name: "New Caledonia" },
    { code: "NZ", name: "New Zealand" },
    { code: "NI", name: "Nicaragua" },
    { code: "NE", name: "Niger" },
    { code: "NG", name: "Nigeria" },
    { code: "NU", name: "Niue" },
    { code: "NF", name: "Norfolk Island" },
    { code: "MP", name: "Northern Mariana Islands" },
    { code: "NO", name: "Norway", in_eea: true },
    { code: "OM", name: "Oman" },
    { code: "PK", name: "Pakistan" },
    { code: "PW", name: "Palau" },
    { code: "PS", name: "Palestinian Territory, Occupied" },
    { code: "PA", name: "Panama" },
    { code: "PG", name: "Papua New Guinea" },
    { code: "PY", name: "Paraguay" },
    { code: "PE", name: "Peru" },
    { code: "PH", name: "Philippines" },
    { code: "PN", name: "Pitcairn" },
    { code: "PL", name: "Poland", in_eea: true },
    { code: "PT", name: "Portugal", in_eea: true },
    { code: "PR", name: "Puerto Rico" },
    { code: "QA", name: "Qatar" },
    { code: "RE", name: "Reunion" },
    { code: "RO", name: "Romania", in_eea: true },
    { code: "RU", name: "Russian Federation" },
    { code: "RW", name: "Rwanda" },
    { code: "SH", name: "Saint Helena" },
    { code: "KN", name: "Saint Kitts and Nevis" },
    { code: "LC", name: "Saint Lucia" },
    { code: "PM", name: "Saint Pierre and Miquelon" },
    { code: "VC", name: "Saint Vincent and The Grenadines" },
    { code: "WS", name: "Samoa" },
    { code: "SM", name: "San Marino" },
    { code: "ST", name: "Sao Tome and Principe" },
    { code: "SA", name: "Saudi Arabia" },
    { code: "SN", name: "Senegal" },
    { code: "RS", name: "Serbia" },
    { code: "SC", name: "Seychelles" },
    { code: "SL", name: "Sierra Leone" },
    { code: "SG", name: "Singapore" },
    { code: "SK", name: "Slovakia", in_eea: true },
    { code: "SI", name: "Slovenia", in_eea: true },
    { code: "SB", name: "Solomon Islands" },
    { code: "SO", name: "Somalia" },
    { code: "ZA", name: "South Africa" },
    { code: "GS", name: "South Georgia and The South Sandwich Islands", blocked: true },
    { code: "ES", name: "Spain", in_eea: true },
    { code: "LK", name: "Sri Lanka" },
    { code: "SD", name: "Sudan" },
    { code: "SR", name: "Suriname" },
    { code: "SJ", name: "Svalbard and Jan Mayen" },
    { code: "SZ", name: "Swaziland" },
    { code: "SE", name: "Sweden", in_eea: true },
    { code: "CH", name: "Switzerland" },
    { code: "SY", name: "Syrian Arab Republic", blocked: true },
    { code: "TW", name: "Taiwan, Province of China" },
    { code: "TJ", name: "Tajikistan" },
    { code: "TZ", name: "Tanzania, United Republic of" },
    { code: "TH", name: "Thailand" },
    { code: "TL", name: "Timor-leste" },
    { code: "TG", name: "Togo" },
    { code: "TK", name: "Tokelau" },
    { code: "TO", name: "Tonga" },
    { code: "TT", name: "Trinidad and Tobago" },
    { code: "TN", name: "Tunisia" },
    { code: "TR", name: "Turkey" },
    { code: "TM", name: "Turkmenistan" },
    { code: "TC", name: "Turks and Caicos Islands" },
    { code: "TV", name: "Tuvalu" },
    { code: "UG", name: "Uganda" },
    { code: "UA", name: "Ukraine" },
    { code: "AE", name: "United Arab Emirates" },
    { code: "GB", name: "United Kingdom" },
    { code: "US", name: "United States" },
    { code: "UM", name: "United States Minor Outlying Islands" },
    { code: "UY", name: "Uruguay" },
    { code: "UZ", name: "Uzbekistan" },
    { code: "VU", name: "Vanuatu" },
    { code: "VE", name: "Venezuela" },
    { code: "VN", name: "Viet Nam" },
    { code: "VG", name: "Virgin Islands, British" },
    { code: "VI", name: "Virgin Islands, U.S." },
    { code: "WF", name: "Wallis and Futuna" },
    { code: "EH", name: "Western Sahara" },
    { code: "YE", name: "Yemen" },
    { code: "ZM", name: "Zambia" },
    { code: "ZW", name: "Zimbabwe" }
]

export {
    transactionStatusList,
    transactionSubStatusList,
    onboardingDocumentTypeList,
    onboardingStatusList,
    onboardingDocumenRefusedTypeList,
    onboardingDocumentStatusList,
    apiEndpoint,
    userRoleList,
    eftyIntegratorId,
    eftyPayAdminId,
    isoCountriesList,
    checkoutSteps,
    allowedIdDocumentTypes,
    descriptionForDocumentTypes,
    thresholdForCreditCardPayments
}