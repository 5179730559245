<template>
  <LoaderSection v-show="isLoading" />
  <main>

    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content pt-4 px-0 px-lg-3">

              <!-- Print button -->
              <div role="button" title="Download" class="btn btn-secondary float-end d-print-none ms-3" @click="printToPDF">
                <font-awesome-icon :icon="['fas', 'download']" class="me-2"/>Download
              </div>

              <!-- Back to transaction button -->
              <router-link :to="{ name: 'publicTransaction', params: { transactionId: transactionId } }" title="Back to transaction" class="btn btn-secondary float-end d-print-none">
                <font-awesome-icon :icon="['fas', 'chevron-left']" class="me-2"/>Back to transaction
              </router-link>

              <ContentPlaceholder v-show="!invoiceItem.id" />

              <div v-if="invoiceItem.id" ref="contentToPrint">

                <!-- Invoice -->
                <div class="container mt-5">
                  <h1 class="fw-bold text-dark" v-if="invoiceItem.type == 'SUCCESS_FEE_INVOICE' || invoiceItem.type == 'EXPRESS_FEE_INVOICE'">Invoice</h1>
                  <h1 class="fw-bold text-dark" v-else-if="invoiceItem.type == 'SELLER_TRANSACTION_OVERVIEW' || invoiceItem.type == 'BUYER_TRANSACTION_OVERVIEW'">Transaction overview</h1>
                  <div class="row mt-4 mb-5">
                    <div class="col-md-6">

                      <!-- Supplier is company -->
                      <div v-if="invoiceItem.supplier.companyName">
                        <h5 class="fw-bold">{{ invoiceItem.supplier.companyName }}</h5>
                        <p class="m-0">{{ invoiceItem.supplier.companyAddress.addressLine1 }}</p>
                        <p class="m-0">{{ invoiceItem.supplier.companyAddress.postalCode }} {{ invoiceItem.supplier.companyAddress.city }}</p>
                        <p class="m-0">{{ invoiceItem.supplier.companyAddress.country }}</p>
                      </div>

                      <!-- Supplier is natural person -->
                      <div v-else>
                        <h5 class="fw-bold">{{ showFullName(invoiceItem.supplier.firstName, invoiceItem.supplier.lastName) }}</h5>
                        <p class="m-0">{{ invoiceItem.supplier.companyAddress.addressLine1 }}</p>
                        <p class="m-0">{{ invoiceItem.supplier.companyAddress.city }}</p>
                        <p class="m-0">{{ invoiceItem.supplier.companyAddress.postalCode }}</p>
                        <p class="m-0">{{ invoiceItem.supplier.companyAddress.country }}</p>
                      </div>

                    </div>
                    <div class="col-md-6">
                      <h5 class="fw-bold">{{ showFullName(invoiceItem.recipient.firstName, invoiceItem.recipient.lastName) }}</h5>
                      <p class="m-0">{{ invoiceItem.recipient.companyAddress.addressLine1 }}</p>
                      <p class="m-0">{{ invoiceItem.recipient.companyAddress.postalCode }} {{ invoiceItem.recipient.companyAddress.city }}</p>
                      <p class="m-0">{{ invoiceItem.recipient.companyAddress.country }}</p>
                    </div>
                  </div>
                  <div class="row mb-2" v-if="invoiceItem.type == 'SUCCESS_FEE_INVOICE' || invoiceItem.type == 'EXPRESS_FEE_INVOICE'">
                    <div class="col-4 col-lg-2 fw-bold">
                      <span>Invoice Number</span>
                    </div>
                    <div class="col-8 col-lg-10">
                      <span>{{ invoiceItem.invoiceNumber }}</span>
                    </div>
                  </div>
                  <div class="row mb-2" v-else>
                    <div class="col-4 col-lg-2 fw-bold">
                      <span>Reference</span>
                    </div>
                    <div class="col-8 col-lg-10">
                      <span>{{ invoiceItem.reference }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-4 col-lg-2 fw-bold">
                      <span>Date</span>
                    </div>
                    <div class="col-8 col-lg-10">
                      <span>{{ parseLocalDate(invoiceItem.created) }}</span>
                    </div>
                  </div>
                  <table class="table mt-5">
                    <thead class="">
                      <tr>
                        <th class="bg-dark-subtle" width="70%">Description</th>
                        <th class="bg-dark-subtle" width="30%">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in filteredLineItems" :key="item.lineNo">
                        <td class="bg-white" :class="{ 'fw-bold fs-5 py-3': item.description === 'Total' }">{{ item.description }}</td>
                        <td class="bg-white" :class="{ 'fw-bold fs-5 py-3': item.description === 'Total' }">{{ parseCurrency(item.currency, item.amount/100) }}</td>
                      </tr>

                      <!-- Parse messages added to the hidden line items -->
                      <tr v-for="item in hiddenLineItems" :key="item.lineNo">
                        <td class="bg-white small text-grey" :class="{ 'fw-bold fs-5 py-3': item.description === 'Total' }" colspan="3">{{ stripHiddenTags(item.description) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="row mt-4 mb-3">
                  <div class="col-12">

                    <div class="p-3 my-4 bg-light text-center mx-auto" role="alert">
                      <p class="m-0 small text-grey">Please be aware that Efty Pay provides invoices and transaction overviews as an additional service for your convenience. It is important to note that these documents are not substitutes for official tax documents. Buyers and sellers are solely responsible for complying with their local tax laws and regulations. This includes the calculation, reporting, and payment of any applicable taxes arising from transactions conducted through Efty Pay. Efty Pay does not provide tax advice and does not accept responsibility for any tax-related obligations.</p>
                    </div>

                  </div>
                </div>

                <!-- Footer if Efty Pay BV is the supplier -->
                <div class="row mt-3 mb-3" v-if="invoiceItem.supplier.id==eftyPayAdminId">
                  <div class="col-12 text-center">
                    <span class="text-muted small">Efty Pay B.V. - Chamber of Commerce (KVK NL): 89044991 - VAT (BTW) Number: 864860821B01 - IBAN (Revolut): LT14 3250 0560 0951 2158 - <a href="https://efty.com/legal/" target="_blank">Legal documents</a></span>
                  </div>
                </div>
                <!-- ./Invoice -->


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { ref, computed } from 'vue';
import html2pdf from 'html2pdf.js';
import LoaderSection from '@/components/public/LoadingSpinner.vue'
import { isLoading, parseLocalDate, showFullName, parseCurrency } from "@/utils/support";
import { eftyPayAdminId } from "@/utils/config";
import dayjs from 'dayjs';
import {
  getInvoiceItem,
  invoiceItem,
  invoiceError
} from "@/utils/invoices.public";
import { useRoute } from 'vue-router';
import ContentPlaceholder from "@/components/public/ContentPlaceholder.vue";

export default {

  setup() {

    const route = useRoute();
    const transactionId = route.params.transactionId
    const contentToPrint = ref(null);
    const hiddenLineItems = ref(null)

    // Computed property to filter lineItems not starting with [HIDDEN]
    const filteredLineItems = computed(() => {
      return invoiceItem.value.lineItems.filter(item => !item.description.startsWith('[HIDDEN]'));
    });

    getInvoiceItem( route.params.userId, route.params.transactionId, route.params.invoiceId ).then( () => {

      if (invoiceItem.value && invoiceItem.value.lineItems) {
        // Filter out the lineItems starting with [HIDDEN] from the original invoiceItem
        invoiceItem.value.lineItems = invoiceItem.value.lineItems.filter(item => item.description.startsWith('[HIDDEN]'))

        // Add the hidden line items to new ref
        hiddenLineItems.value = invoiceItem.value.lineItems.filter(item => item.description.startsWith('[HIDDEN]'))
      }

    })

    function stripHiddenTags(description) {
      return description.replace(/\[HIDDEN\]|\[\/HIDDEN\]/g, '');
    }

    const printToPDF = () => {

      const element = contentToPrint.value;
      const options = {
        margin: 0.2,
        filename: 'Efty Pay ' + invoiceItem.value.invoiceNumber + '.pdf',
        image: { type: 'jpeg', quality: 1 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
      };

      html2pdf().from(element).set(options).save();
    };

    // expose to template and other options API hooks
    return {
      showFullName,
      dayjs,
      isLoading,
      invoiceError,
      invoiceItem,
      parseCurrency,
      ContentPlaceholder,
      transactionId,
      contentToPrint,
      printToPDF,
      parseLocalDate,
      eftyPayAdminId,
      filteredLineItems,
      hiddenLineItems,
      stripHiddenTags
    }

  },
  name: 'InvoiceItemView',
  components: {ContentPlaceholder, LoaderSection }
}
</script>