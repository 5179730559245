<template>
  <main ref="root">
    <NavigationLightCheckoutSection />
    <LoaderSection v-show="isLoading" />
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">

              <div class="row mt-2">
                
                <div class="col-12">

                  <div class="transaction-steps-wrapper d-flex mb-5 mt-3 mt-lg-5">

                    <span v-for="checkoutStep in checkoutSteps" :key="checkoutStep" class="step" :class="{ active: currentCheckoutStep == checkoutStep.enum || (currentCheckoutStep == 4 && checkoutStep.enum == 3) /* Small hack for cc completed page */, finish: currentCheckoutStep > checkoutStep.enum  }">{{ checkoutStep.title }}</span>

                  </div>
                  
                </div>
                
              </div>
              
              <div class="row mt-2">

                  <!-- Express methods -->
                  <div class="col-12 col-lg-7" v-if="selectedPaymentMethod == 'cc'">
                    <div class="card bg-white border-0">
                      <div class="card-body p-0 ps-3">

                        <div v-if="currentCheckoutStep == 3">
                          <h3 class="fw-bold text-dark mb-2">Express payment methods</h3>
                          <p class="mb-4">Please complete the payment below.</p>
                        </div>

                        <div v-if="mangopayCheckoutError">
                          <div class="alert alert-danger mt-3" role="alert">
                            <span class="text-dark fw-bold"><font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="me-2" />Your payment failed</span> with the following details: {{ mangopayCheckoutError }}
                          </div>
                        </div>

                        <div id="express_methods" ref="expressMethods">

                        </div>

                        <div v-if="currentCheckoutStep == 3" class="w-100">
                          <button class="btn btn-lg btn-light w-100 rounded-0 rounded-start mb-3" role="button" type="button" @click="gotoPreviousCheckoutStep()">Go back</button>
                        </div>

                      </div>
                    </div>
                  </div>

                  <!-- Billing details -->
                  <div class="col-12 col-lg-7 mb-3" v-if="currentCheckoutStep == 0">

                    <div class="card bg-white border-0">

                      <div class="card-body p-0 ps-3">

                        <form action="" method="get" class="h-100" id="checkoutForm">

                          <h3 class="fw-bold text-dark mb-2">Billing details</h3>
                          <p class="mb-4">Please fill out your details below. We will use this information to calculate VAT (if applicable), generate your invoice and set up the secure transaction process.</p>

                          <div class="row">

                            <div class="col-12">

                              <div class="input-group mt-3" >
                                <div class="form-check form-switch" @change="prepareVat()">
                                  <input class="form-check-input" type="checkbox" id="onboardingType" @change="onIsLegalEntityChange($event)" :checked="checkoutFormDetails.onboardingType == true">
                                  <label class="form-check-label" for="onboardingType">I represent a company</label>
                                </div>
                              </div>

                              <div class="" v-if="isLegalEntity">

                                <div class="input-group mt-3">

                                  <div class="form-floating clearfix">
                                    <input type="text" class="form-control" id="registeredName" name="registeredName" placeholder="" required :value="checkoutFormDetails.registeredName">
                                    <label for="registeredName" class="form-label">Company name</label>
                                  </div>

                                </div>

                                <div class="input-group mt-3">

                                  <div class="form-floating clearfix">
                                    <input type="text" class="form-control" id="companyNumber" name="companyNumber" placeholder="" required :value="checkoutFormDetails.companyNumber">
                                    <label for="companyNumber" class="form-label">Company registration number</label>
                                  </div>

                                </div>

                                <div class="input-group mt-3">

                                  <div class="form-floating clearfix">
                                    <input type="text" class="form-control" id="companyVatNumber" name="companyVatNumber" placeholder="" :value="checkoutFormDetails.companyVatNumber">
                                    <label for="companyVatNumber" class="form-label">Company VAT number</label>
                                  </div>
                                </div>

                                <hr class="mt-3 mb-0">

                              </div>

                            </div>
                          </div>

                          <div class="row">

                            <div class="col-12">

                              <div class="form-floating mt-3">
                                <input type="text" class="form-control" id="firstName" name="firstName" placeholder="" required :value="checkoutFormDetails.firstName">
                                <label for="firstName" class="form-label">First Name</label>
                              </div>

                              <div class="form-floating mt-3" >
                                <input type="text" class="form-control" id="lastName" name="lastName" placeholder="" required :value="checkoutFormDetails.lastName" >
                                <label for="lastName" class="form-label">Last Name</label>
                              </div>

                              <div class="form-floating mt-3" >
                                <select class="form-select mb-3" required id="country" name="country" @change="prepareVat()">
                                  <option selected></option>
                                  <option v-for="isoCountry in isoCountriesList" :key="isoCountry.code" :value="isoCountry.code" :selected="checkoutFormDetails.country ? isoCountry.code === checkoutFormDetails.country : isoCountry.code === clientCountry ">{{ isoCountry.name }}</option>
                                </select>
                                <label for="country" class="form-label">Country</label>
                              </div>

                              <div class="form-floating mt-3" >
                                <div class="form-floating">
                                  <input type="phone" class="form-control" id="phoneNumber" name="phoneNumber" placeholder="" required :value="checkoutFormDetails.phoneNumber" >
                                  <label for="phone" class="form-label">Phone number</label>
                                </div>
                              </div>


                              <div class="input-group mt-3">
                                <div class="form-floating">
                                  <input type="email" class="form-control" id="email" name="email" placeholder="" required :value="checkoutFormDetails.email" >
                                  <label for="email" class="form-label">E-mail address</label>
                                </div>
                              </div>

                              <div class="input-group mt-3">
                                <div class="form-floating">
                                  <input type="email" class="form-control" id="emailConfirm" name="emailConfirm" placeholder="" required :value="checkoutFormDetails.emailConfirm" @paste.prevent>
                                  <label for="emailConfirm" class="form-label">E-mail address (confirm)</label>
                                </div>
                              </div>

                              <div class="form-check mt-3">
                                <input class="form-check-input" type="checkbox" value="" id="agreeTerms" name="agreeTerms" required :checked="checkoutFormDetails.agreeTerms" >
                                <label class="form-check-label small" for="agreeTerms">
                                  I agree to Efty Pay B.V.'s <a href="https://efty.com/legal/buyers-mandate/" target="_blank">BUYER'S MANDATE</a>, <a href="https://efty.com/legal/general-terms-and-privacy-policy/" target="_blank">GENERAL TERMS & CONDITIONS AND PRIVACY POLICY</a> and <a href="https://efty.com/legal/sale-and-purchase-agreement/" target="_blank">SALE AND PURCHASE AGREEMENT</a> and have read them carefully. Furthermore, I agree to Mangopay's <a href="https://mangopay.com/terms-and-conditions/payment-services" target="_blank">Terms and Conditions for Payment Services Users of European Platforms</a>. By continuing I confirm I have read and agree to these mandates, terms and conditions.
                                </label>
                              </div>

                              <div class="btn btn-lg w-100 btn-success mt-3" role="submit" @click="submitStep()">Continue <span class="d-none d-lg-inline-block text-white">to payment</span></div>

                            </div>
                          </div>

                        </form>

                      </div>


                    </div>

                  </div>
                  <!-- ./Billing details -->


                  <!-- Payment method -->
                  <div class="col-12 col-lg-7 mb-3" v-if="currentCheckoutStep == 1">
                    <div class="card bg-white border-0">

                      <div class="card-body p-0 ps-3">

                        <form action="" method="get" class="h-100" id="paymentMethodForm">

                          <h3 class="fw-bold text-dark mb-2">Payment method</h3>
                          <p class="mb-4">For the transaction of this domain name we support payment via wire transfer<span v-if="creditCardAllowed"> and credit card</span>. Pay for your domain name via our secure checkout process. Efty Pay keeps your funds safe while instructing the seller to transfer the domain name to you.</p>

                          <div :class="['card border paymentmethod bg-white border-dark', selectedPaymentMethod === 'wire' ? 'selected' : '']" @click="creditCardAllowed ? changePaymentMethod('wire') : null">
                            <div class="card-body text-center" role="button">
                              <div class="fw-bold text-dark"><font-awesome-icon :icon="['fas', 'check']" v-if="selectedPaymentMethod === 'wire'" class="me-2"/>Wire Transfer</div>
                              <div>Estimated transfer completion: {{ dayjs().businessDaysAdd(3).format('dddd MMM D') }}<span data-bs-toggle="tooltip" data-bs-placement="top"  data-bs-original-title="When you pay via wire transfer, the completion of your transaction usually takes two business days to complete, after the funds have arrived on the bank account of our payment processor. No rights can be derived from this estimation, based on the specifics of your transfer, it could take shorter or longer to complete." data-bs-html="true" class="ms-2"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span></div>
                            </div>
                          </div>

                          <div :class="['card border paymentmethod bg-white border-dark mt-3', selectedPaymentMethod === 'cc' ? 'selected' : '', !creditCardAllowed ? 'disabled' : '']" @click="creditCardAllowed ? changePaymentMethod('cc') : null">
                            <div class="card-body text-center">
                              <div class="fw-bold text-dark"><font-awesome-icon :icon="['fas', 'check']" v-if="selectedPaymentMethod === 'cc'" class="me-2"/>Express Check-Out (+3%)</div>
                              <div class="text-dark">Estimated transfer completion: {{ dayjs().businessDaysAdd(1).format('dddd MMM D') }}<span v-if="creditCardAllowed" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="When you pay via credit card, the completion of your transaction usually takes one business day to complete. Your payment is processed instantly, after which an Efty Pay transfer specialist will provide you with detailed instructions regarding the completion of the transfer of your domain name." data-bs-html="true" class="ms-2"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span></div>
                              <img src="@/assets/payment.png" class="img-responsive payment-img mt-3">
                            </div>
                          </div>

                          <div class="w-100 mt-5">
                            <button class="btn btn-lg btn-light w-50 rounded-0 rounded-start" role="button" type="button" @click="gotoPreviousCheckoutStep()">Go back</button>
                            <button class="btn btn-lg btn-success w-50 rounded-0 rounded-end" role="button" type="button" @click="submitStep()" >Continue <span class="d-none d-lg-inline-block text-white">to next step</span></button>
                          </div>

                        </form>

                      </div>

                    </div>
                  </div>
                  <!-- ./Payment method -->


                  <!-- Confirmation -->
                  <div class="col-12 col-lg-7 mb-3" v-if="currentCheckoutStep == 2">
                    <div class="card bg-white border-0">

                      <div class="card-body p-0 ps-3">

                        <form action="" method="get" class="h-100" id="confirmationForm">

                          <div class="alert alert-danger" v-if="transactionError">
                            {{ transactionError }}
                          </div>

                          <div class="alert alert-danger" v-if="bankWirePayInError">
                            {{ bankWirePayInError }}
                          </div>

                          <h3 class="fw-bold text-dark mb-2">Confirmation</h3>
                          <p class="mb-4">Please check the details of your order below. If you need to make changes, you can go back to the previous step</p>

                          <div class="row">
                            <div class="col-6 col-lg-4 fw-bold">
                              I represent a company
                            </div>
                            <div class="col-6 col-lg-8">
                              <span v-if="checkoutFormDetails.onboardingType == true">Yes</span>
                              <span v-else>No</span>
                            </div>
                          </div>

                          <div class="row mt-3" v-if="checkoutFormDetails.onboardingType == true">
                            <div class="col-6 col-lg-4 fw-bold">
                              Company name
                            </div>
                            <div class="col-6 col-lg-8">
                              {{ checkoutFormDetails.registeredName }}
                            </div>
                          </div>

                          <div class="row mt-3" v-if="checkoutFormDetails.onboardingType == true">
                            <div class="col-6 col-lg-4 fw-bold">
                              Company registration number
                            </div>
                            <div class="col-6 col-lg-8">
                              {{ checkoutFormDetails.companyNumber }}
                            </div>
                          </div>

                          <div class="row mt-3" v-if="checkoutFormDetails.onboardingType == true">
                            <div class="col-6 col-lg-4 fw-bold">
                              Company VAT number
                            </div>
                            <div class="col-6 col-lg-8">
                              {{ checkoutFormDetails.companyVatNumber }}
                            </div>
                          </div>

                          <hr>

                          <div class="row mt-3">
                            <div class="col-6 col-lg-4 fw-bold">
                              First Name
                            </div>
                            <div class="col-6 col-lg-8">
                              {{ checkoutFormDetails.firstName }}
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col-6 col-lg-4 fw-bold">
                              Last Name
                            </div>
                            <div class="col-6 col-lg-8">
                              {{ checkoutFormDetails.lastName }}
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col-6 col-lg-4 fw-bold">
                              Country
                            </div>
                            <div class="col-6 col-lg-8">
                              {{ checkoutFormDetails.country }}
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col-6 col-lg-4 fw-bold">
                              Phone number
                            </div>
                            <div class="col-6 col-lg-8">
                              {{ checkoutFormDetails.phoneNumber }}
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col-6 col-lg-4 fw-bold">
                              E-mail address
                            </div>
                            <div class="col-6 col-lg-8">
                              {{ checkoutFormDetails.email }}
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col-6 col-lg-4 fw-bold">
                              Payment method
                            </div>
                            <div class="col-6 col-lg-8">
                              {{ selectedPaymentMethod === 'wire' ? 'Wire Transfer' : 'Credit Card' }}
                            </div>
                          </div>

                          <div class="w-100 mt-5">
                            <button class="btn btn-lg btn-light w-50 rounded-0 rounded-start" role="button" type="button" @click="gotoPreviousCheckoutStep()" >Go back</button>
                            <button class="btn btn-lg btn-success w-50 rounded-0 rounded-end" role="button" type="button" @click="submitStep()" >Continue <span class="d-none d-lg-inline-block text-white">to next step</span></button>
                          </div>

                        </form>

                      </div>

                    </div>
                  </div>
                  <!-- ./Confirmation -->


                  <!-- Transfer Instructions -->
                  <div class="col-12 col-lg-10 offset-lg-1 mb-3" v-if="currentCheckoutStep == 3 && selectedPaymentMethod == 'wire'">

                    <div class="row">

                      <div class="col-12 ps-3">

                        <!-- Payment instructions for wire transfer -->
                        <div v-if="selectedPaymentMethod=='wire'">
                          <h3 class="fw-bold">Payment instructions</h3>
                          <p>Please make a wire transfer using the details below. These details have also been e-mailed to you. After the funds have been secured, the transfer of the domain will be initiated.</p>
                          <p>Efty Pay has partnered with Mangopay, an EU Licensed and Regulated Financial Institution, to collect your funds.</p>
                          <p>To ensure a swift process, please make sure you wire the funds with <strong>the correct payment reference {{ bankWirePayInItem.WireReference }}</strong> and IBAN/BIC. Also, make sure the amount matches the one below exactly.</p>
                          <div class="row mt-4" v-if="bankWirePayInItem.DeclaredDebitedFunds">
                            <div class="col-12 col-lg-4 fw-bold">
                              Total amount
                            </div>
                            <div class="col-12 col-lg-8">
                              {{ parseCurrency(bankWirePayInItem.DeclaredDebitedFunds.Currency, bankWirePayInItem.DeclaredDebitedFunds.Amount / 100) }}
                            </div>
                          </div>

                          <div class="row mt-2" v-if="bankWirePayInItem.BankAccount">
                            <div class="col-12 col-lg-4 fw-bold">
                              Account Holder Name
                            </div>
                            <div class="col-12 col-lg-8">
                              {{ bankWirePayInItem.BankAccount.OwnerName }}
                              <span data-bs-toggle="tooltip" data-bs-placement="top" data-bs-original-title="Efty Pay has partnered with Mangopay, an EU Licensed and Regulated Financial Institution, to collect your funds." data-bs-html="true" class="me-2"><font-awesome-icon :icon="['fas', 'circle-question']" class="text-secondary" /></span>
                              <font-awesome-icon :icon="['fas', 'copy']" @click="copyToClipboard(bankWirePayInItem.BankAccount.OwnerName)" role="button" title="Copy to clipboard" />
                            </div>
                          </div>

                          <div class="row mt-2" v-if="bankWirePayInItem.BankAccount">
                            <div class="col-12 col-lg-4 fw-bold">
                              IBAN
                            </div>
                            <div class="col-12 col-lg-8">
                              {{ bankWirePayInItem.BankAccount.IBAN }}
                              <font-awesome-icon :icon="['fas', 'copy']" @click="copyToClipboard(bankWirePayInItem.BankAccount.IBAN)" role="button" title="Copy to clipboard" />
                            </div>
                          </div>

                          <div class="row mt-2" v-if="bankWirePayInItem.BankAccount">
                            <div class="col-12 col-lg-4 fw-bold">
                              BIC/SWIFT
                            </div>
                            <div class="col-12 col-lg-8">
                              {{ bankWirePayInItem.BankAccount.BIC }}
                              <font-awesome-icon :icon="['fas', 'copy']" @click="copyToClipboard(bankWirePayInItem.BankAccount.BIC)" role="button" title="Copy to clipboard" />
                            </div>
                          </div>

                          <div class="row mt-2" v-if="bankWirePayInItem.BankAccount">
                            <div class="col-12 col-lg-4 fw-bold">
                              Address
                            </div>
                            <div class="col-12 col-lg-8">
                              <div>{{ bankWirePayInItem.BankAccount.OwnerAddress.AddressLine1 }}</div>
                              <span>{{ bankWirePayInItem.BankAccount.OwnerAddress.PostalCode }} {{ bankWirePayInItem.BankAccount.OwnerAddress.City }} ({{ bankWirePayInItem.BankAccount.OwnerAddress.Country }})</span>
                              <font-awesome-icon :icon="['fas', 'copy']" @click="copyToClipboard(bankWirePayInItem.BankAccount.OwnerAddress.AddressLine1 + ' ' + bankWirePayInItem.BankAccount.OwnerAddress.PostalCode + ' ' + bankWirePayInItem.BankAccount.OwnerAddress.City + ' ' + bankWirePayInItem.BankAccount.OwnerAddress.Country)" role="button" title="Copy to clipboard" class="ms-1" />
                            </div>
                          </div>

                          <div class="row mt-2" v-if="bankWirePayInItem.BankAccount">
                            <div class="col-12 col-lg-4 fw-bold">
                              Payment Reference / Description
                            </div>
                            <div class="col-12 col-lg-8">
                              <div>
                                {{ bankWirePayInItem.WireReference }}
                                <font-awesome-icon :icon="['fas', 'copy']" @click="copyToClipboard(bankWirePayInItem.WireReference)" role="button" title="Copy to clipboard" />
                              </div>
                            </div>
                          </div>

                          <hr class="d-lg-none mt-4">

                          <div class="mt-4">Sign into Efty Pay <b>to confirm your payment</b> and to track the progress of your transaction.</div>

                          <div class="btn btn-success btn-lg mt-3" @click="sendMagicLink()">Sign into Efty Pay</div>

                        </div>

                      </div>

                    </div>


                  </div>
                  <!-- ./Transfer Instructions -->

                <!-- Transfer Instructions -->
                <div class="col-12 col-lg-10 offset-lg-1 mb-3" v-if="currentCheckoutStep == 4">

                  <div class="row">

                    <div class="col-12 ps-3">

                      <!-- CC Payment success -->
                      <div>
                        <h3 class="fw-bold">Payment Successful!</h3>
                        <p>Thank you for your purchase. Your payment has been successfully processed.</p>
                        <p>We have requested the seller to transfer the domain name to Efty Pay. Once we receive and verify the domain, we will send you detailed instructions on how to complete the transfer to your account.</p>
                        <p>To track the progress of your transaction, please log in to Efty Pay. You can also directly communicate with our team of transfer specialists there.</p>

                        <div class="btn btn-success btn-lg mt-3" @click="sendMagicLink()">Sign into Efty Pay</div>

                      </div>

                    </div>

                  </div>


                </div>
                <!-- ./CC Payment success -->

                  <div class="col-12 col-lg-5 mb-3">

                    <div class="sticky-top" v-if="(currentCheckoutStep != 3 && currentCheckoutStep != 4 )|| (currentCheckoutStep == 3 && selectedPaymentMethod == 'cc')">
                      <div class="card bg-medium border-0" v-if="transactionItem">

                        <div class="card-body p-4">

                          <div v-if="vatItem.amountIncludingVat">
                            <h3 class="mb-3 fw-bold text-dark">Order summary</h3>

                            <div class="row mb-2" v-if="transactionItem.digitalAsset">
                              <div class="col-6 fw-bold text-dark">{{ transactionItem.digitalAsset.domain.domainName }}</div>
                              <div class="col-6 text-end fw-bold text-dark"><span id="amountBeforeVat">{{ parseCurrency(transactionItem.currency, transactionAmountBeforeVat) }}</span></div>
                            </div>

                            <div class="row mb-2" v-if="vatItem && vatItem.chargeVat">

                              <div class="col-5 text-dark">
                                <span class="text-dark" v-if="vatItem.vatReverseCharge">VAT Reverse charge</span>
                                <span class="text-dark" v-else-if="vatItem.vatOneStopShop">VAT (OSS)</span>
                                <span class="text-dark" v-else-if="vatItem.vatOutOfScope">VAT Out of scope</span>
                                <span class="text-dark" v-else-if="vatItem.vatPercentage > 0">VAT ({{ vatItem.vatPercentage/100 }}%)</span>
                              </div>

                              <div class="col-7 text-end text-dark">
                                <span class="text-dark" v-if="vatItem.vatOutOfScope">-</span>
                                <span class="text-dark" v-else-if="vatItem.vatReverseCharge">-</span>
                                <span class="text-dark" v-else>{{ parseCurrency(transactionItem.currency, vatItem.vatAmount/100) }}</span>
                              </div>

                            </div>

                            <hr>

                            <div class="row mb-2">
                              <div class="col-7 text-dark">Transfer support</div>
                              <div class="col-5 text-end text-success fw-bold"><font-awesome-icon :icon="['fas', 'check']" class="me-2"/>Included</div>
                            </div>

                            <div v-if="expressFeeAmount > 0">

                              <hr>

                              <div class="row mb-2">
                                <div class="col-7 text-dark">Express Fee</div>
                                <div class="col-5 text-end text-dark">{{ parseCurrency(transactionItem.currency, expressFeeAmount) }}</div>
                              </div>

                              <div class="row mb-2" v-if="expressFeeVatItem">

                                <div class="col-5 text-dark">
                                  <span class="text-dark" v-if="expressFeeVatItem.vatReverseCharge">VAT Reverse charge</span>
                                  <span class="text-dark" v-else-if="expressFeeVatItem.vatOneStopShop">VAT (OSS)</span>
                                  <span class="text-dark" v-else-if="expressFeeVatItem.vatOutOfScope">VAT Out of scope</span>
                                  <span class="text-dark" v-else-if="expressFeeVatItem.vatPercentage > 0">VAT ({{ expressFeeVatItem.vatPercentage/100 }}%)</span>
                                </div>

                                <div class="col-7 text-end text-dark">
                                  <span class="text-dark" v-if="expressFeeVatItem.vatOutOfScope">-</span>
                                  <span class="text-dark" v-else-if="expressFeeVatItem.vatReverseCharge">-</span>
                                  <span class="text-dark" v-else>{{ parseCurrency(transactionItem.currency, expressFeeVatItem.vatAmount/100) }}</span>
                                </div>

                              </div>

                            </div>

                            <hr>

                            <div class="row">
                              <div class="col-4 fw-bold text-dark h4">Total</div>
                              <div class="col-8 text-end"><span id="transactionTotalAmount" class="fw-bold text-dark h5">{{ parseCurrency(transactionItem.currency, totalAmountIncludingFeesAndVat) }}</span></div>
                            </div>

                          </div>

                          <div v-else class="py-5 text-center my-auto">

                            <img src="@/assets/loader.gif" class="my-5"/>

                          </div>

                        </div>

                      </div>

                      <div class="row mt-3">
                        <div class="col-12 text-center">
                          <span class="d-block text-dark fw-bold small" title="Excellent score on Trustpilot">Excellent</span>
                          <img src="@/assets/trustpilot/stars.svg" class="img-responsive img-trustpilot stars" title="Excellent score on Trustpilot"><br>
                          <img src="@/assets/trustpilot/logo.png" class="img-responsive img-trustpilot logo" title="Excellent score on Trustpilot">
                        </div>
                      </div>

                      <div class="card bg-white border mt-3 d-none">

                        <div class="card-body p-4 py-2">

                          <div class="pl-3 mt-3 text-grey">

                            <font-awesome-icon :icon="['fas', 'check']" class="fw-bold text-dark me-2"/><span class="fw-bold text-dark lead">Buy it now</span>
                            <p>Pay for your domain name via our secure checkout process. Efty Pay keeps your funds safe while instructing the seller to transfer the domain name to you.</p>

                            <font-awesome-icon :icon="['fas', 'check']" class="fw-bold text-dark me-2"/><span class="fw-bold text-dark lead">Transfer</span>
                            <p>Once we have secured your payment, our team will send you tailored transfer instructions and guide you through securing your domain name.</p>

                            <font-awesome-icon :icon="['fas', 'check']" class="fw-bold text-dark me-2"/><span class="fw-bold text-dark lead">Done</span>
                            <p>Your transaction is now complete, and Efty Pay will pay the seller.</p>

                          </div>

                        </div>
                      </div>
                    </div>

                  </div>

                </div>

          </div>
        </div>

      </div>
      <FooterLightSection />

    </div>

  </main>

</template>

<script>
// Mangopay SDK Imports
import { CheckoutSdk } from '@mangopay/checkout-sdk';
import { createCardRegistration, createPayIn, mangopayCheckoutError, mpBranding } from "@/utils/mangopay.checkout.public";

// Component Imports
import LoaderSection from "@/components/public/LoadingSpinner.vue";
import FooterLightSection from "@/components/public/FooterLight.vue";
import NavigationLightCheckoutSection from "@/components/public/NavigationLightCheckout.vue";

// Environment Imports
import {mangopayEnvironment, mangopayClientId} from '@/utils/environment.config'

// Utility Imports
import {
  isEntityTypeBusiness,
  isEntityTypeOrganisation,
  isEntityTypeSoleProprietorship,
  isLegalEntity
} from "@/utils/onboarding";
import {
  bankWirePayInError,
  bankWirePayInItem,
  createBankWirePayInItemWithEncryptedData,
  getBankWirePayInItemWithEncryptedData
} from "@/utils/payments.public";
import {
  getTransactionItemWithEncryptedData,
  transactionItem
} from "@/utils/transactions.public";
import {
  generateRandomPassword,
  isLoading,
  parseCurrency
} from "@/utils/support";
import {
  isoCountriesList,
  checkoutSteps
} from "@/utils/config";
import {
  getCurrentCheckoutStep,
  currentCheckoutStep,
  updateTransactionWithEncryptedData,
  transactionError,
  sendMagicLinkToBuyer,
  getThresholdForCreditCardPayments,
  userEmailItem,
  getUserByEmail
} from "@/utils/checkout.public";
import {
  getVatCalculation,
  vatItem,
  expressFeeVatError,
  expressFeeVatItem,
  getExpressFeeVatCalculation
} from "@/utils/vat.public";

// Vue & Third-party Imports
import { ref, onMounted, watch } from "vue";
import { useRoute } from 'vue-router';
import dayjs from "dayjs";
import dayjsBusinessDays from 'dayjs-business-days';
import { Tooltip } from "bootstrap";
import Swal from "sweetalert2";

// Store Imports
import { useCheckoutStore } from "@/stores/checkout";


export default {
  inheritAttrs: false,

  mounted() {

    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    })

  },

  name: 'PublicCheckout',
  setup () {

    dayjs.extend(dayjsBusinessDays);

    isLoading.value = true

    const root = ref(null)
    const route = useRoute()

    const checkoutStore = useCheckoutStore()
    const checkoutStoreDetails = ref(0)

    const newTransaction = ref(0)
    const newBuyer = ref(0)

    const creditCardAllowed = ref(false)
    const selectedPaymentMethod = ref('wire')
    const expressMethods = ref(null)

    // Get params from query
    const encryptedData = route.query.d
    const iv = route.query.i
    const keyId = route.query.k
    const paymentComplete = ref(false)
    const nextCheckoutStep = ref(0)
    const totalAmountIncludingFeesAndVat = ref(0)

    // Get transaction with data from querystring
    getTransactionItemWithEncryptedData(encryptedData, iv, keyId)

    // Get bank wire PayIn encrypted with data from querystring
    getBankWirePayInItemWithEncryptedData(encryptedData, iv, keyId)

    const clientIpAddress = ref(0)
    const clientCountry = ref(0)
    const transactionAmountBeforeVat = ref(0)
    const transactionVatAmount = ref(0)
    const transactionTotalAmount = ref(0)
    const checkoutFormDetails = ref([])
    const expressFeeAmount = ref(0)
    const totalAssetInclVat = ref(0)
    const totalExpressFeeInclVat = ref(0)
    const totalAmountForCcCap = ref(0)

    // Get country from IP Address with retry mechanism
    const getCountryFromIp = async (retries = 2, delay = 1000) => {
      for (let attempt = 1; attempt <= retries; attempt++) {
        try {
          if (!clientIpAddress.value) {
            const response = await fetch('https://ipapi.co/json/');
            const { ip, country } = await response.json();
            clientIpAddress.value = ip;
            clientCountry.value = country;
            break; // Exit loop if successful
          }
        } catch (error) {
          if (attempt === retries) {
            console.error('Failed to fetch country from IP after multiple attempts:', error);
          } else {
            console.warn(`Attempt ${attempt} failed. Retrying in ${delay}ms...`);
            await new Promise(resolve => setTimeout(resolve, delay));
          }
        }
      }
    };

    watch(transactionItem, context => {

      if (context){

        // Get current step in checkout process
        getCurrentCheckoutStep(context)

        // Set proper transaction amounts for front end
        transactionAmountBeforeVat.value = context.assetAmountExcVat / 100
        transactionVatAmount.value = false // @Todo: Now defaults to 0 VAT but should be in line with data from API
        transactionTotalAmount.value = context.assetAmountIncVat / 100

        // Get earlier entered data from cookie
        checkoutStoreDetails.value = checkoutStore.returnParsedJson

        // load the cookie data into the form, if any
        if (checkoutStoreDetails.value && checkoutStoreDetails.value.firstName) {
          checkoutFormDetails.value = checkoutStoreDetails.value
          if (checkoutStoreDetails.value.onboardingType == true) {
            isLegalEntity.value = true // Company
          }
        }
        // Else, check if there is a buyer in the transaction object. Then load that data into the form
        else if (transactionItem.value.buyer && transactionItem.value.buyer.id) {

          checkoutFormDetails.value.firstName       = transactionItem.value.buyer.firstName
          checkoutFormDetails.value.lastName        = transactionItem.value.buyer.lastName
          checkoutFormDetails.value.email           = transactionItem.value.buyer.email
          checkoutFormDetails.value.onboardingType  = transactionItem.value.buyer.representsCompany
          checkoutFormDetails.value.phoneNumber     = transactionItem.value.buyer.phoneNumber

          if (transactionItem.value.buyer.representsCompany == true) {
            checkoutFormDetails.value.registeredName    = transactionItem.value.buyer.companyName
            isLegalEntity.value                         = transactionItem.value.buyer.representsCompany
            checkoutFormDetails.value.companyNumber     = transactionItem.value.buyer.companyRegistrationNumber
            checkoutFormDetails.value.companyVatNumber  = transactionItem.value.buyer.vatSettings ? transactionItem.value.buyer.vatSettings.vatNumber : false
          }

          //checkoutFormDetails.value.firstName   = transactionItem.value.buyer.firstName
        }

        getCountryFromIp().then(() => {
          getTotalAmountIncludingFeesAndVat()
        })

      }

      // Do not pull VAT after wire transfer is initiated
      if (currentCheckoutStep.value < 3) {
        prepareVat()
      }

    });

    onMounted(() => {

    })

    // Change the selected payment method
    const changePaymentMethod = (method) => {

      if (method === 'wire' || method === 'cc') {
        selectedPaymentMethod.value = method;

        if (method === 'cc') {

          expressFeeAmount.value = transactionItem.value.assetAmountExcVat * 0.03 / 100

          // Pick buyer country from FORM
          if (checkoutFormDetails.value.country) {

            getExpressFeeVatCalculation(
                Math.round(expressFeeAmount.value * 100), // Avoid floating-point issues
                checkoutFormDetails.value.country,
                checkoutFormDetails.value.onboardingType ? true : false
            ).then( () => {
              getTotalAmountIncludingFeesAndVat()
            })
          }

        } else if (method === 'wire') {
          expressFeeVatItem.value = false
          expressFeeAmount.value = 0
          getTotalAmountIncludingFeesAndVat()
        }
      } else {
        console.error('Invalid payment method selected');
      }
    };

    const getTotalAmountIncludingFeesAndVat = () => {

      totalAmountIncludingFeesAndVat.value = 0 // Reset
      totalAmountIncludingFeesAndVat.value = Number(transactionItem.value.assetAmountExcVat / 100) // Asset incl VAT
      totalAmountIncludingFeesAndVat.value += vatItem.value.vatAmount ? vatItem.value.vatAmount / 100 : 0 // Transaction VAT
      totalAmountIncludingFeesAndVat.value += expressFeeVatItem.value.amountIncludingVat ? expressFeeVatItem.value.amountIncludingVat / 100 : 0 // Express fee incl VAT

      // Check if an express transfer is allowed
      if (transactionItem.value.currency) {

        // This is the total amount to calculate the cc cap on. Lets assume there is 21% VAT on the express fee
        totalAssetInclVat.value = Number(transactionItem.value.assetAmountExcVat) + Number(vatItem.value.vatAmount)
        totalExpressFeeInclVat.value = totalAssetInclVat.value * 0.03 * 1.21
        totalAmountForCcCap.value = (Number(totalAssetInclVat.value) + Number(totalExpressFeeInclVat.value)) / 100

        if (totalAmountForCcCap.value  < getThresholdForCreditCardPayments(transactionItem.value.currency)) {
          creditCardAllowed.value = true
        }

      }
    }

    // Check if VAT can be calculated
    const prepareVat = ( ) => {

      // Do not pull VAT after wire transfer is initiated
      if (currentCheckoutStep && currentCheckoutStep.value < 3) {

        // Pick buyer country from FORM
        if (root.value && root.value.querySelector && root.value.querySelector('#country') && root.value.querySelector('#country').value) {

          getVatCalculation(
              Math.round(transactionItem.value.assetAmountExcVat), // Avoid floating-point issues
              transactionItem.value.seller.representsCompany,
              transactionItem.value.seller.companyAddress && transactionItem.value.seller.companyAddress.country ? transactionItem.value.seller.companyAddress.country : 'US', // @Todo: needs fix
              transactionItem.value.seller.representsCompany,
              root.value.querySelector('#country').value,
              root.value.querySelector('#onboardingType').checked ? true : false).then( () => {
                getTotalAmountIncludingFeesAndVat()
          })

        }
        // Else pull it from the IP address
        else {

          getCountryFromIp().then( () => {

              getVatCalculation(
                  Math.round(transactionItem.value.assetAmountExcVat), // Avoid floating-point issues
                  transactionItem.value.seller.representsCompany,
                  transactionItem.value.seller.companyAddress && transactionItem.value.seller.companyAddress.country ? transactionItem.value.seller.companyAddress.country : 'US', // @Todo: needs fix
                  transactionItem.value.seller.representsCompany,
                  clientCountry.value,
                  false).then( () => {
                getTotalAmountIncludingFeesAndVat()
              })


            })

        }
      }

    }

    // Change legal status of onboarding user based on event change on select item
    const onIsLegalEntityChange = ( event ) => {

      if (currentCheckoutStep.value == 0) {

        if ( event.target.checked == true) {

          isLegalEntity.value = true // Company

        } else {

          isLegalEntity.value = false // Natural person

        }

      }

    }

    // Change legal status of onboarding user based on event change on select item
    const onCompanyTypeChange = ( event ) => {

      if (currentCheckoutStep.value == 0) {

        if (event.target.options[event.target.options.selectedIndex].value == 'BUSINESS') {

          isEntityTypeBusiness.value = true // Business
          isEntityTypeOrganisation.value = false // Organisation
          isEntityTypeSoleProprietorship.value = false // Sole proprietorship

        } else if (event.target.options[event.target.options.selectedIndex].value == 'ORGANIZATION') {

          isEntityTypeBusiness.value = false // Business
          isEntityTypeOrganisation.value = true // Organisation
          isEntityTypeSoleProprietorship.value = false // Sole proprietorship

        } else if (event.target.options[event.target.options.selectedIndex].value == 'SOLETRADER') {

          isEntityTypeBusiness.value = false // Business
          isEntityTypeOrganisation.value = false // Organisation
          isEntityTypeSoleProprietorship.value = true // Sole proprietorship

        }

      }

    }

    const gotoPreviousCheckoutStep = (  ) => {

      // Clear iFrame with cc payment from MP
      if (expressMethods.value) {
        expressMethods.value.innerHTML = '';
      }

      for (let i = 1; i <10; i++) {
        let tempPrevStep = currentCheckoutStep.value - i
        if (checkoutSteps.value[tempPrevStep]) {
          nextCheckoutStep.value = tempPrevStep
          break
        }
      }

      // Calculate next step
      changeCheckoutStep( nextCheckoutStep.value )

    }

    const submitStep = (  ) => {

      if (currentCheckoutStep.value == 0) {

        if (!root.value.querySelector('form#checkoutForm').checkValidity()) {

          root.value.querySelector('form#checkoutForm').reportValidity()

        } else {

          // Set form details into object for later usage
          checkoutFormDetails.value =
              {
                'onboardingType': root.value.querySelector('#onboardingType').checked ? true : false,
                'registeredName': root.value.querySelector('#registeredName') ? root.value.querySelector('#registeredName').value : '',
                'companyNumber': root.value.querySelector('#companyNumber') ? root.value.querySelector('#companyNumber').value : '',
                'companyVatNumber': root.value.querySelector('#companyVatNumber') ? root.value.querySelector('#companyVatNumber').value : '',
                'firstName': root.value.querySelector('#firstName').value,
                'lastName': root.value.querySelector('#lastName').value,
                'country': root.value.querySelector('#country').value,
                'email': root.value.querySelector('#email').value,
                'emailConfirm': root.value.querySelector('#emailConfirm').value,
                'agreeTerms': root.value.querySelector('#agreeTerms').checked ? true : false,
                'phoneNumber': root.value.querySelector('#phoneNumber').value
              }

          // First, check if there is an Efty Pay user with this email address
          getUserByEmail(encryptedData, iv, keyId,checkoutFormDetails.value.email)

          // Extra front end validation on email
          if (checkoutFormDetails.value.email == checkoutFormDetails.value.emailConfirm) {

            // Save user details into store
            checkoutStore.update(JSON.stringify(checkoutFormDetails.value))

            // Load updated cookie data
            checkoutStoreDetails.value = checkoutStore.returnParsedJson

            // Go to next step in checkout flow
            gotoNextCheckoutStep()

          } else {

            Swal.fire({
              toast: true,
              position: 'center',
              showConfirmButton: true,
              timerProgressBar: true,
              ConfirmButtonText: 'Okay',
              customClass: {
                closeButton: 'btn btn-lg btn-secondary',
                confirmButton: 'btn btn-lg btn-success order-last',
                denyButton: 'btn btn-lg btn-secondary',
                cancelButton: 'btn btn-lg btn-secondary'
              },
              icon: 'error',
              title: 'Please properly confirm your e-mail address',
            })

          }


        }

      }

      else if (currentCheckoutStep.value == 1) {

        gotoNextCheckoutStep()

      }

      else if (currentCheckoutStep.value == 2) {

        Swal.fire({
          toast: false,
          position: 'center',
          showConfirmButton: true,
          showCancelButton: true,
          timerProgressBar: false,
          confirmButtonText: 'Yes, continue',
          cancelButtonText: 'No, edit details',
          customClass: {
            closeButton: 'btn btn-lg btn-secondary',
            confirmButton: 'btn btn-lg btn-success order-last',
            denyButton: 'btn btn-lg btn-secondary',
            cancelButton: 'btn btn-lg btn-secondary'
          },
          icon: 'info',
          title: 'Are your billing details correct?',
          text: 'We use these to kick off the transfer of your domain name, and generate your invoice. Therefore it\'s important all details are correct.'
        }).then((result) => {

          if (result.value) {

            newTransaction.value = transactionItem.value

            // Only create buyer in transaction if buyer is undefined
            if (typeof newTransaction.value.buyer === 'undefined') {

              // Existing buyer
              if (userEmailItem.value && userEmailItem.value.id) {

                newBuyer.value = {
                  id: userEmailItem.value.id
                }

              }
              // New buyer
              else {

                newBuyer.value = {

                  firstName: checkoutFormDetails.value.firstName,
                  lastName: checkoutFormDetails.value.lastName,
                  email: checkoutFormDetails.value.email,
                  username: checkoutFormDetails.value.email,
                  userType: 'BUYER_OR_SELLER',
                  status: 'ACTIVE',
                  password: generateRandomPassword(),
                  phoneNumber: checkoutFormDetails.value.phoneNumber,
                  website: '-', // @Todo: Should not be required
                  paymentDetails: {mangopayDetails: {}}, // @Todo: Should not be required

                }

                if (checkoutFormDetails.value.onboardingType == true) {
                  newBuyer.value.representsCompany = checkoutFormDetails.value.onboardingType
                  newBuyer.value.companyName = checkoutFormDetails.value.registeredName
                  newBuyer.value.companyRegistrationNumber = checkoutFormDetails.value.companyRegistrationNumber
                  newBuyer.value.vatSettings = {}
                  newBuyer.value.vatSettings.vatNumber = checkoutFormDetails.value.companyVatNumber
                }

                if (newBuyer.value.companyAddress) {
                  newBuyer.value.companyAddress.country = checkoutFormDetails.value.country
                } else {
                  newBuyer.value.companyAddress = {country: checkoutFormDetails.value.country}
                }

              }

              newTransaction.value.buyer = newBuyer.value

            }
            // Buyer already exists in this transaction? Only update form fields
            else {

              newTransaction.value.buyer.firstName = checkoutFormDetails.value.firstName
              newTransaction.value.buyer.lastName = checkoutFormDetails.value.lastName
              newTransaction.value.buyer.email = checkoutFormDetails.value.email
              newTransaction.value.buyer.phoneNumber = checkoutFormDetails.value.phoneNumber

              if (checkoutFormDetails.value.onboardingType == true) {
                newTransaction.value.buyer.representsCompany = checkoutFormDetails.value.onboardingType
                newTransaction.value.buyer.companyName = checkoutFormDetails.value.registeredName
                newTransaction.value.buyer.companyRegistrationNumber = checkoutFormDetails.value.companyRegistrationNumber
                if (newTransaction.value.buyer.vatSettings) {
                  newTransaction.value.buyer.vatSettings.vatNumber = checkoutFormDetails.value.companyVatNumber
                } else {
                  newTransaction.value.buyer.vatSettings = {vatNumber: checkoutFormDetails.value.companyVatNumber}
                }
              }

              if (newTransaction.value.buyer.companyAddress) {
                newTransaction.value.buyer.companyAddress.country = checkoutFormDetails.value.country
              } else {
                newTransaction.value.buyer.companyAddress = {country: checkoutFormDetails.value.country}
              }

            }

            updateTransactionWithEncryptedData(newTransaction.value,encryptedData, iv, keyId ).then( () => {
              if (!transactionError.value) {
                if (selectedPaymentMethod.value == 'wire') {
                  createBankWirePayInItemWithEncryptedData(encryptedData, iv, keyId).then(() => {
                    if (!bankWirePayInError.value) {

                      // Clear cookie data
                      checkoutStore.update(JSON.stringify({}))
                      checkoutStore.$reset()

                      // All is well? Proceed to next step
                      gotoNextCheckoutStep()
                    }
                  })
                } else if (selectedPaymentMethod.value == 'cc') {

                  getCountryFromIp()

                  const browserInfo = {
                    AcceptHeader: 'text/html, application/xhtml+xml, application/xml;q=0.9,/;q=0.8',
                    JavaEnabled: navigator.javaEnabled(),
                    Language: navigator.language,
                    ColorDepth: window.screen.colorDepth,
                    ScreenHeight: window.screen.height,
                    ScreenWidth: window.screen.width,
                    TimeZoneOffset: new Date().getTimezoneOffset(),
                    UserAgent: navigator.userAgent,
                    JavascriptEnabled: true
                  }

                  const expressMethodsDiv = root.value.querySelector('#express_methods')

                  const mpOptions = {
                    branding: mpBranding,
                    clientId: mangopayClientId,
                    environment: mangopayEnvironment,
                    profilingMerchantId: "139032",
                    amount: {
                      value: (
                              Number(vatItem.value.amountIncludingVat) +
                              Number(expressFeeVatItem.value ? expressFeeVatItem.value.amountIncludingVat : 0)
                              ),
                      currency: transactionItem.value.currency
                    },
                    paymentMethods: [
                      {
                        type: "card",
                        options: {
                          supportedCardBrands: ["VISA", "MASTERCARD", "MAESTRO", "CB"],
                          onCreateCardRegistration:  (cardType) => {
                            return createCardRegistration(cardType, encryptedData, iv, keyId)
                          },
                          onCreatePayment: (data) => {
                            return createPayIn(encryptedData, iv, keyId, data.CardId, browserInfo, clientIpAddress.value)
                          }
                        }
                      }
                    ]
                  }

                  CheckoutSdk.loadCheckoutSdk(expressMethodsDiv, mpOptions).then(( mangopaySdk ) => {

                    mangopaySdk.on('paymentComplete', ( event ) => {

                      // First check for 3DS failure
                      if (event.detail.Status.toLowerCase() === 'failed') {

                        mangopayCheckoutError.value = 'We were unable to complete your payment ('+event.detail.ResultMessage+'). Please try again or contact your bank for further assistance.'

                        Swal.fire({
                          toast: true,
                          position: 'bottom-right',
                          showConfirmButton: false,
                          timer: 5000,
                          timerProgressBar: true,
                          icon: 'error',
                          title: 'Error in payment',
                          text: event.detail.ResultMessage
                        })

                      } else {

                        // Empty the content of the express methods container
                        expressMethods.value.innerHTML = '';

                        paymentComplete.value = true

                        // Force progression to success page
                        currentCheckoutStep.value = 4

                        Swal.fire({
                          toast: true,
                          position: 'bottom-right',
                          showConfirmButton: false,
                          timer: 2000,
                          timerProgressBar: true,
                          icon: 'success',
                          title: 'Payment successful',
                        })
                      }
                    });

                    mangopaySdk.on('error', (event) => {

                      mangopayCheckoutError.value = event.detail.error.ResultMessage
                      Swal.fire({
                        toast: true,
                        position: 'bottom-right',
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                        icon: 'error',
                        title: 'Error in payment',
                        text: event.detail.error.ResultMessage
                      })
                    })

                  })

                  // All is well? Proceed to next step
                  gotoNextCheckoutStep()

                }
              }
            })

          }
        })

      }

      else if (currentCheckoutStep.value == 3) {

        gotoNextCheckoutStep()

      }


    }

    const gotoNextCheckoutStep = async (  ) => {
      for (let i = 1; i <10; i++) {
        let tempNextStep = currentCheckoutStep.value + i
        if (checkoutSteps.value[tempNextStep]) {
          nextCheckoutStep.value = tempNextStep
          break
        }
      }

      // Calculate next step
      changeCheckoutStep( nextCheckoutStep.value )

    }

    const changeCheckoutStep = ( newStepEnum ) => {

      // Reset any errors
      bankWirePayInError.value = false
      transactionError.value = false
      expressFeeVatError.value = false
      mangopayCheckoutError.value = false

      // Scroll to top of window
      window.scrollTo(0,0)

      // Reset
      currentCheckoutStep.value = newStepEnum

    }

    const sendMagicLink = () => {

     sendMagicLinkToBuyer(encryptedData, iv, keyId).then( () => {

       Swal.fire({
         toast: false,
         position: 'center',
         showConfirmButton: true,
         timer: 10000,
         timerProgressBar: true,
          customClass: {
            closeButton: 'btn btn-lg btn-secondary',
            confirmButton: 'btn btn-lg btn-success order-last',
            denyButton: 'btn btn-lg btn-secondary',
            cancelButton: 'btn btn-lg btn-secondary'
          },
         icon: 'success',
         title: 'We\'ve sent you an e-mail with the magic link that you can use to access Efty Pay.'
       })

      })

    }

    const copyToClipboard = (text) => {
      navigator.clipboard.writeText(text).then(() => {
        Swal.fire({
          customClass: {
            closeButton: 'btn btn-lg btn-secondary',
            confirmButton: 'btn btn-lg btn-success order-last',
            denyButton: 'btn btn-lg btn-secondary',
            cancelButton: 'btn btn-lg btn-secondary'
          },
          toast: true,
          position: 'bottom-right',
          showConfirmButton: false,
          timerProgressBar: true,
          timer:3000,
          icon: 'success',
          text: 'Copied to the clipboard!',
        })
      }).catch(err => {
        console.error('Could not copy text: ', err);
      });
      return false
    };

    return {
      onIsLegalEntityChange,
      root,
      transactionItem,
      isLoading,
      isLegalEntity,
      isEntityTypeBusiness,
      isEntityTypeOrganisation,
      isEntityTypeSoleProprietorship,
      onCompanyTypeChange,
      isoCountriesList,
      clientCountry,
      parseCurrency,
      checkoutSteps,
      transactionAmountBeforeVat,
      transactionVatAmount,
      transactionTotalAmount,
      currentCheckoutStep,
      gotoPreviousCheckoutStep,
      submitStep,
      dayjs,
      dayjsBusinessDays,
      checkoutStoreDetails,
      bankWirePayInError,
      transactionError,
      checkoutFormDetails,
      bankWirePayInItem,
      sendMagicLink,
      prepareVat,
      vatItem,
      copyToClipboard,
      creditCardAllowed,
      selectedPaymentMethod,
      changePaymentMethod,
      expressFeeVatError,
      expressFeeVatItem,
      expressFeeAmount,
      totalAmountIncludingFeesAndVat,
      paymentComplete,
      expressMethods,
      mangopayCheckoutError
    }
  },
  components: { LoaderSection,FooterLightSection, NavigationLightCheckoutSection }
}
</script>
