import axios from 'axios'
import { ref } from 'vue'
import { apiEndpoint } from "@/utils/config";
import { useAuth0Store } from '@/stores/auth0'

const userList = ref(0)
const userItem = ref(0)
const userError = ref(0)

// Get list with transactions
const getUserList = ( integratorId ) => {

    const auth0Store = useAuth0Store()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${auth0Store.auth0Token}`
    }

    // Reset
    userList.value = false
    userError.value = false

    axios
        .post(apiEndpoint.value + 'users/list', { integratorId: integratorId },{
                headers: axiosHeaders
            }
        ).then(response => {
        userList.value = response.data
    }).catch(error => {
        userError.value = "There is an error"

        if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

            // Redirect to 403 page (work around vue router bc this function is called from within the router)
            //window.location.href = '/#/public/403/';

        }

    })

}

// Get content for a specific transaction
const getUserItem = ( userId, integratorId ) => {

    const auth0Store = useAuth0Store()

    const axiosHeaders = {
        'Access-Control-Request-Method': 'POST',
        Origin: 'localhost',
        Authorization: `${auth0Store.auth0Token}`
    }

    userItem.value = false

    if ( userId ) {

        userError.value = false

        axios.get(apiEndpoint.value + 'users/id/' + userId + '/?integratorId=' + integratorId, {
                    headers: axiosHeaders
                }
            ).then(response => {
                userItem.value = response.data
            }).catch(error => {
                userError.value = "There is an error"

                if (error.response.status == 500 || error.response.status == 403) { // @Todo change to 403 only after API fix

                    // Redirect to 403 page (work around vue router bc this function is called from within the router)
                    //window.location.href = '/#/public/403/';

                }

            });
    }

}

const getMangopayStatusShort = ( status ) => {

    if (status == 'LIGHT') {
        return 'Started, not yet verified'
    } else if (status == 'REGULAR') {
        return 'Verified'
    } else {
        return 'Not started'
    }

}

const getMangopayStatusLong = ( status ) => {

    if (status == 'LIGHT') {
        return 'The user started onboarding, but is not yet verified by Mangopay'
    } else if (status == 'REGULAR') {
        return 'The user has successfully completed the verification process and is ready to receive pay-outs'
    } else {
        return 'The user has not started the onboarding with Mangopay'
    }

}

export {
    getUserItem,
    getUserList,
    getMangopayStatusShort,
    getMangopayStatusLong,
    userList,
    userItem,
    userError
}