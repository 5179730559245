/* Import basic Vue structure */
import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'

/* State management with Pinia */
import { createPinia } from 'pinia'

/* Auth0 */
import {createAuth0} from '@auth0/auth0-vue';

/* Bootstrap bundle 5.3 */
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

/* Font awesome library */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* Specific FA SOLID icons */
import { faChevronDown, faChevronUp,faPhone, faCog, faListCheck,faChevronLeft, faPenClip, faFileContract, faCircleChevronRight, faDownload, faCopy, faCircleXmark,faCircleCheck, faLock, faExternalLinkAlt, faFileCirclePlus, faRoute, faArrowCircleUp, faChevronRight, faCircleExclamation, faSquare, faCircleQuestion, faCloudUploadAlt, faAsterisk, faCartShopping, faHourglass, faBars, faTrashAlt, faExclamationTriangle, faRotate, faRecycle, faCloudArrowUp, faCommentsDollar, faChartLine, faHandHoldingHand, faGear, faArrowRightFromBracket, faCheck, faRightLeft, faMoneyBillTransfer, faFlagCheckered, faBan, faClockRotateLeft, faRobot, faXmark } from '@fortawesome/free-solid-svg-icons'

/* Specific FA REGULAR icons */
import { faAddressCard, faCompass, faEnvelope, faRectangleXmark, faLightbulb, faFilePdf, faFileImage, faLifeRing, faBell, faAddressBook, faRectangleList, faComments, faUser, faCreditCard, faEye, faPenToSquare } from '@fortawesome/free-regular-svg-icons'

/* Specific FA BRAND icons */
import { faTwitter } from '@fortawesome/free-brands-svg-icons'

/* Add icons to library */
library.add(faChevronDown, faChevronUp, faPhone, faCog,faListCheck,faChevronLeft, faPenClip, faFileContract, faAddressCard, faCircleChevronRight,faDownload, faCopy, faLock, faCircleXmark, faCircleCheck, faExternalLinkAlt,faFileCirclePlus, faRoute, faArrowCircleUp, faChevronRight, faCircleExclamation, faSquare, faCircleQuestion, faCloudUploadAlt, faAsterisk, faCompass, faTwitter,faEnvelope,faCartShopping,faHourglass,faBars,faTrashAlt,faExclamationTriangle, faRotate,faRecycle,faRectangleXmark, faLightbulb, faCloudArrowUp, faFileImage, faFilePdf, faLifeRing, faChartLine, faAddressBook, faHandHoldingHand, faBell, faRectangleList, faComments, faGear, faUser, faCreditCard, faArrowRightFromBracket, faCheck, faRightLeft, faMoneyBillTransfer, faFlagCheckered, faBan, faEye, faPenToSquare, faClockRotateLeft, faRobot, faXmark, faCommentsDollar)

/* Auth0 Variables */
import {auth0ClientId, auth0Audience, auth0Domain, auth0RedirectUri} from '@/utils/environment.config'
const pinia = createPinia()
const app = createApp(App);

app
    .use(router)
    .use(pinia)
    .use(
        createAuth0({
            domain: auth0Domain,
            clientId: auth0ClientId, /* Needs to be set as an ENV variable at some point */
            authorizationParams: {
                redirect_uri: auth0RedirectUri,
                audience: auth0Audience
            }
        })
    )
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount("#eftypay");