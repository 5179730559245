<template>
  <LoaderSection v-show="isLoading" />
  <NavigationSection />
  <main ref="root">
    <div class="main-content-wrapper w-100">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2 bg-white rounded" id="mainContent">
            <div class="main-content p-3 pt-4">
              <div class="clearfix my-auto">
                <h1 class="fw-bold h3 text-dark float-start"><font-awesome-icon :icon="['far', 'fa-compass']" />Transactions</h1>
                <form class="float-end" method="get" action="" id="integratorForm">
                  <div class="form-floating">
                      <select class="form-select bg-light" id="integratorId" name="integratorId" required>
                        <option value="1WTManOJBP7EKsy2TElo7I" selected="selected">Efty Investor</option>
                      </select>
                    <label for="environment">Integrator</label>
                  </div>
                </form>
                <form class="float-end me-3" id="domainSearchForm" @submit.prevent="processDomainSearch">
                  <div class="form-floating">
                    <input type="text" class="form-control" name="searchDomain" id="searchDomain" ref="searchDomain" v-model="searchBox">
                    <label for="searchDomain">Search domain name</label>
                  </div>
                </form>
              </div>
              <div class="bg-white border p-3 mt-4">

                <div class="table-responsive">
                  <table class="table table-responsive table-borderless bg-white">
                    <thead class="">
                    <tr>
                      <th scope="col" width="20%" class="text-muted fw-bold lead bg-white">Domain</th>
                      <th scope="col" width="10%" class="text-muted fw-bold lead bg-white">Price</th>
                      <th scope="col" width="5%" class="text-muted fw-bold lead bg-white">Buyer</th>
                      <th scope="col" width="5%" class="text-muted fw-bold lead bg-white">Seller</th>
                      <th scope="col" width="5%" class="text-muted fw-bold lead bg-white">Date</th>
                      <th scope="col" width="55%" class="text-muted fw-bold lead bg-white">Status</th>
                    </tr>
                    <tr>
                      <td colspan="6" class=" bg-white"></td>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-if="!transactionList && !transactionError">
                          <td colspan="6" class=" bg-white"><ContentPlaceholder /></td>
                        </tr>
                        <tr v-else-if="transactionError">
                          <td colspan="6" class=" bg-white">
                            <ErrorMessage />
                          </td>
                        </tr>
                        <tr v-else-if="!transactionList.length">
                          <td colspan="6" class="text-center bg-white">
                            <img src="@/assets/notfound.png" class="img-fluid ">
                            <div class="w-100 text-center">
                              <font-awesome-icon :icon="['fas', 'xmark']" class="me-2" />No transactions found
                            </div>
                          </td>

                        </tr>
                        <tr v-for="transactionItem in transactionList" :key="transactionItem.id">
                          <td class=" my-auto bg-white">
                            <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">

                              <!-- Display icon if admin needs to perform an action -->
                              <component :is="componentInteractionMap[transactionItem.id]" v-if="componentInteractionMap[transactionItem.id]" class="me-2"><font-awesome-icon :icon="['fas', 'circle-exclamation']" class="text-danger h5 m-0"/></component>

                              <span class="text-success fw-bold lead p-o m-0 w-100">{{transactionItem.digitalAsset.domain.domainName}}</span><br>
                              <span class="" v-if="transactionItem.utmParameters"><font-awesome-icon :icon="['fas', 'asterisk']" class="me-2" />{{ transactionItem.utmParameters.utmSource }}</span>
                            </router-link>
                          </td>
                          <td class=" lead my-auto bg-white">
                            <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              {{ parseCurrency( transactionItem.currency , transactionItem.assetAmountExcVat/100) }}
                            </router-link>
                          </td>
                          <td class=" my-auto text-center bg-white">
                            <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              <span class="bg-buyer text-white rounded px-2 py-1 lead" v-if="transactionItem.buyer">{{showInitials(transactionItem.buyer.firstName, transactionItem.buyer.lastName).toUpperCase()}}</span>
                            </router-link>
                          </td>
                          <td class=" my-auto text-center bg-white">
                            <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              <span class="bg-seller text-white rounded px-2 py-1 lead" v-if="transactionItem.seller">{{showInitials(transactionItem.seller.firstName, transactionItem.seller.lastName).toUpperCase()}}</span>
                            </router-link>
                          </td>
                          <td class="lead my-auto bg-white">
                            <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">
                              {{dayjs(transactionItem.created).format('ddd MMMM D, YYYY')}}
                            </router-link>
                          </td>
                          <td class="lead my-auto bg-white no-max-width">
                            <router-link :to="{ name: 'adminTransaction', params: { transactionId: transactionItem.id } }" title="View transaction">

                              <!-- Different status for buyer and seller, show both -->

                              <span class="d-block">
                                <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-buyer" /><span class="">{{getTransactionDeepStatusMapping(transactionItem.status, 'buyer').title}}</span>
                              </span>
                              <span class="d-block">
                                <font-awesome-icon :icon="['fas', 'square']" class="me-2 text-seller" /><span class="">{{getTransactionDeepStatusMapping(transactionItem.status, 'seller').title}}</span>
                              </span>


                            </router-link>
                          </td>
                        </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSection />
    </div>
  </main>
</template>

<script>
import FooterSection from '@/components/admin/Footer.vue'
import LoaderSection from '@/components/admin/LoadingSpinner.vue'
import NavigationSection from "@/components/admin/Navigation.vue";
import ContentPlaceholder from '@/components/admin/ContentPlaceholder.vue'
import ErrorMessage from '@/components/admin/ErrorMessage.vue'
import {
  getTransactionList,
  filterTransactions,
  transactionList,
  transactionItem,
  activeFilter,
  transactionStatusList,
  transactionError
} from "@/utils/transactions.admin";
import {
  getTransactionDeepStatusMapping, getRegistrarStatus
} from "@/utils/transactions.common";
import {
  showInitials, parseCurrency, isLoading
} from "@/utils/support";
import {
  eftyIntegratorId
} from "@/utils/config";
import dayjs from 'dayjs';
import { ref } from 'vue'
import {getDeepInteractionWidgetComponent} from "@/utils/interactionWidget";

export default {

  setup() {

    const componentInteractionMap = ref({});
    const searchDomain = ref('');

    const loadTransactions = ( searchValue = false) => {

      // Load transactions
      getTransactionList( eftyIntegratorId, searchValue, 100 ).then( () => {

        // For each transaction, check if there are interactive components
        if (transactionList.value) {
          transactionList.value.forEach(record => {
            fetchComponentForRecord(record);
          });
        }

      })

    }

    loadTransactions()

    const processDomainSearch = () => {

      loadTransactions(searchDomain.value.value)

    }

    // Function used to map transactionItems in a list and check if there are interaction components attached to it
    const fetchComponentForRecord = async (transactionItem) => {

      // Check if EP has a holding account with the registrar
      const registrarStatus = getRegistrarStatus(transactionItem);

      // Get an interaction widget, if any
      const response = await getDeepInteractionWidgetComponent(transactionItem.status, 'admin', registrarStatus)

      // Set it into the array with transaction IDs
      if (response) {
        componentInteractionMap.value[transactionItem.id] = response.interactionComponent;
      }
    };

    // expose to template and other options API hooks
    return {
      transactionList,
      transactionItem,
      filterTransactions,
      activeFilter,
      transactionStatusList,
      transactionError,
      showInitials,
      dayjs,
      parseCurrency,
      isLoading,
      getTransactionDeepStatusMapping,
      componentInteractionMap,
      processDomainSearch,
      searchDomain
    }

  },
  name: 'AdminTransactionListView',
  components: { ErrorMessage, LoaderSection, FooterSection, NavigationSection, ContentPlaceholder }
}
</script>
