import axios from 'axios'
import { ref } from 'vue'
import Swal from "sweetalert2";
import {router} from "@/router";

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(localizedFormat);

const whoisRecords = ref(0)
const isLoading = ref(false)

const parseLocalDate = ( date ) => {

    if (date) {
        return dayjs(date).locale(navigator.language).format('L');
    }

}

const checkIfUserShouldBeAdmin = () => {
    const checkForAdmin = router.currentRoute.value.meta.is_admin;
    return checkForAdmin
}

const showInitials = ( firstName, lastName ) => {

    if ( firstName && lastName ) {
        return Array.from(firstName)[0] + Array.from(lastName)[0]
    } else if (firstName) {
        return Array.from(firstName)[0]
    } else if (lastName) {
        return Array.from(lastName)[0]
    } else {
        return '?'
    }

}
const showFullName = ( firstName, lastName ) => {

    if ( firstName && lastName ) {
        return firstName + ' ' + lastName
    } else if (firstName) {
        return firstName
    } else if (lastName) {
        return lastName
    } else {
        return '-'
    }
}

const parseCurrency = ( currency, amount ) => {

    if (currency && currency != 'CURRENCY_NONE') {
        const formattedAmount = new Intl.NumberFormat('en-US', {style: 'currency', currency: currency}).format(amount)
        return currency + ' ' + formattedAmount
    } else {
        return false
    }

}
const refreshWhois = ( transactionId ) => {

    if ( transactionId ) {

        setTimeout(() => axios
            .get('transaction-list.json')
            .then(response => {
                whoisRecords.value = response.data
                Swal.fire({
                    toast: true,
                    position: 'bottom-right',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    icon: 'success',
                    title: 'Whois request initiated successfully'
                })
            }), 500);
        }


}
const generateRandomPassword = (letters, numbers, either) => {

    var chars = [
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz", // letters
        "0123456789", // numbers
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789" // either
    ];

    function randInt(this_max) { // return int between 0 and this_max - 1
        let umax = Math.pow(2, 32);
        let max = umax - (umax % this_max);
        let r = new Uint32Array(1);
        do {
            crypto.getRandomValues(r);
        } while(r[0] > max);
        return r[0] % this_max;
    }

    function randCharFrom(chars) {
        return chars[randInt(chars.length)];
    }

    function shuffle(arr) { // https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle#The_modern_algorithm
        for (let i = 0, n = arr.length; i < n - 2; i++) {
            let j = randInt(n - i);
            [arr[j], arr[i]] = [arr[i], arr[j]];
        }
        return arr;
    }

    return shuffle([letters, numbers, either].map(function(len, i) {
        return Array(len).fill(chars[i]).map(x => randCharFrom(x)).join('');
    }).concat().join('').split('')).join('')
}

export {
    refreshWhois,
    showInitials,
    showFullName,
    checkIfUserShouldBeAdmin,
    parseCurrency,
    isLoading,
    generateRandomPassword,
    parseLocalDate
}